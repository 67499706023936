import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import countrylist from "./CountryCodeList";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

interface FormStructure{
  "id": string,
  "type": string,
  "attributes": {
      "id": 1,
      "name": string,
      "first_name": null,
      "last_name": null,
      "phone_number": null,
      "email": null,
      "gender": null,
      "country": null,
      "industry": null,
      "message": null
  }
}

interface FormStructure{
  "id": string,
  "type": string,
  "attributes": {
      "id": 1,
      "name": string,
      "first_name": null,
      "last_name": null,
      "phone_number": null,
      "email": null,
      "gender": null,
      "country": null,
      "industry": null,
      "message": null
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const { baseURL: apiBaseUrl } = require("./../../../framework/src/config");

interface StateType {
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string;
  selectedGender: string;
  selectedPhoneCountry: string;
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  countries: Array<object>;
  gender: Array<Gender>;
  country: Array<Country>;
  industry: Array<Industry>;
  selectedIndustry: string;
  token_local: string | null;
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;
  formikData: FormikData;
  // Customizable Area Start

  selectedValue: string,
  navigationNum: number,
  numQut: number
  sliderValue:number
  snackbarOpenRental:boolean
  token:string
  emailValidt:boolean;
  shareResutEmail:string
  pathUrl:string
  tokenForSendEmail:string
  zipcode: string;
  vaildZipcode: boolean;
  modalOPen:boolean;
  validCapthcByBE:boolean
  acknowledgeCheckbox: boolean;
  termsAndConditionCheckbox: boolean; 
  selectedBtn: any;
  detiledQuestions:any
  simplequestions:any
  allQuestions: any
  selectedBtnArray: number[];
  resultByPoint:any
  chatgptResponse:Array<Object>
  chatgptUnqId:number;
  electionDetails: Array<Object>;
  isCircularProgressIndicator: boolean;
  isErrorModalOpen: boolean;
  // Customizable Area End
}

interface FormikData {
  first_name: string;
  last_name: string;
  email: string;
  selectedPhoneCountry: string;
  phone_number: string;
  selectedGender?: string;
  selectedCountry?: string;
  message?: string;
  selectedIndustry?: string;
  countries: Array<object>;
}

interface CustomTypeData {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyData;
  };
  endPoint: string;
}

interface CustomTypeBodyData {
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    gender: string;
    country: string;
    industry: string;
    message: string;
  };
  type: string;
}

interface CustomTypeSubmitvalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  countries: Array<object>;
  selectedGender?: string;
  selectedCountry?: string;
  selectedIndustry?: string;
  message?: string;
}

interface CustomTypevalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  selectedGender: string;
  selectedCountry: string;
  selectedIndustry: string;
  message: string;
}

export interface CountriesList {
  Countries: Array<Countries>;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  getQuizApiCallId : string = "";
  getCategoryApiCallId : string = "";
  createAccountGetMail : string = "";
  getchatgptApiCallId:string=""
  getresultByPointApiCallId:string=""
  apiCallGetResultId: string = "";
  paramsId: string = "";
  apiCallResultsPageShare: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      countries: countrylist,
      formikData: {
        selectedCountry: "1",
        selectedGender: "1",
        selectedIndustry: "education",
        first_name: "",
        last_name: "",
        email: "",
        selectedPhoneCountry: "",
        message: "",
        phone_number: "",
        countries: countrylist,
      },

      // Add more countries as neede
      gender: [
        { label: "Male", value: "1" },
        { label: "Female", value: "2" },
      ],
      country: [
        { label: "India", value: "1" },
        { label: "America", value: "2" },
        { label: "Canada", value: "3" },
        { label: "England", value: "4" },
      ],
      industry: [
        { label: "Education", value: "education" },
        { label: "Food", value: "food" },
        { label: "Marketing", value: "marketing" },
      ],
      selectedIndustry: "",
      token_local: localStorage.getItem("loginTokenBlock"),
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      // Customizable Area Start
      selectedValue: "",
      navigationNum: 0,
      numQut: 0,
      sliderValue:0,
      snackbarOpenRental:false,
      token:'',
      zipcode: "",
      modalOPen:false,
      emailValidt:true,
      shareResutEmail:'',
      pathUrl:'',
      termsAndConditionCheckbox: false,
      acknowledgeCheckbox: false,
      vaildZipcode: false,
      validCapthcByBE:true,
      simplequestions: [],
      allQuestions: [],
      selectedBtn: [],
      detiledQuestions: [],
      tokenForSendEmail:'',
      selectedBtnArray: [],
      resultByPoint:[],
      chatgptResponse:[],
      electionDetails: [],
      chatgptUnqId:0,
      isCircularProgressIndicator: false,
      isErrorModalOpen: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderIdFailureCallBack = async (responseJson: string) => {
    alert("@@@@====>");
  };

  async componentDidMount(){

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const id = url.searchParams.get('shareId');
    if(id){
      this.handleResultPageShow(id);
    }

    removeStorageData("captcha_token")

    this.createAccount()
    let data = "";
    data = await getStorageData("token")
    if(data != ""){
      this.getApi(data)
    }

    const mostSutableCandidates = localStorage.getItem('chatgpt');
    if(mostSutableCandidates != null){
      this.setState({chatgptResponse: JSON.parse(mostSutableCandidates)});
    }
    const electionDetailsL = localStorage.getItem('groupedData');
    if(electionDetailsL != null){
      this.setState({electionDetails: JSON.parse(electionDetailsL)});
    }
  }

  handleResultPageShow(id: string){
    const header = {"Content-Type": "application/json"};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCallResultsPageShare = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_chatgpt3/chatgpts/quizresults?id=${id}` 
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createAccount = () => {
    let data = {
      data: {
        type: "email_account",  
        attributes:{
          email:  "deepori2@2on89yopmail.com",
        }
      },
    };
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountGetMail =requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return true
  }


handleResForCreateAccount = async (from: string, message: Message) => {
  if (
    this.createAccountGetMail ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
   

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.setState({token:responseJson.account.meta.token})
setStorageData("token",responseJson.account.meta.token)
    
  }
};
  getApi = async(token:string) => {
    this.createAccount()
    
    let data = token;
    
    const header = {
      "Content-Type": "application/json",
      token:data
    }; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategory
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
  };

  handleResForQuestionStats = async (from: string, message: Message) => {

    if (
      this.getQuizApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({ allQuestions: responseJson.data})
        responseJson.data?.map((Cvl:any)=>{
          
         this.setState({
          selectedBtn: [...this.state.selectedBtn,{
            value:Cvl.attributes.name,
            clk:false,
            id: Cvl.id
          }]
         })
        })   
      }
    }
  };

 
getCategoryApi = () => {
  this.createAccount()

  const header = {
    "Content-Type":"application/json",
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCategoryApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.categoryEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  const httpBody = 
  {
    linkshare: {
        email: this.state.shareResutEmail,
        link: this.state.pathUrl
    }
}

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
);
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleResForQuestionStatsCategory = async (from: string, message: Message) => {
  if (
    this.getCategoryApiCallId ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    this.setState({ modalOPen: false,snackbarOpenRental:true});

  }
};

getresultByPointApi = async() => {
  this.createAccount()
  let zipcodeFromLocalStorage=await getStorageData("Zipcode")

  const header = {
    "Content-Type":"application/json",
    token:this.state.token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getresultByPointApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.result_By_Point}?ballotpedia[zip_code]=${zipcodeFromLocalStorage}` 
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
 
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleResForresultByPoint = async (from: string, message: Message) => {

  if (
    this.getresultByPointApiCallId ==  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson.error){
      this.setState({isErrorModalOpen: true});
      this.props.navigation.navigate('Multipageforms')

      return;
    }
    this.setState({ resultByPoint:[...this.state.resultByPoint,responseJson],modalOPen: false,snackbarOpenRental:true},async()=>{
     await   this.chatgptApi()

    });
  }
};


chatgptApi = async() => {
this.createAccount()
  let questionAnswer = [];
  questionAnswer = await getStorageData("questionAnswer")
  
  let resultBypoint = JSON.stringify(this.state.resultByPoint)

  let electionDetailsString = JSON.stringify(this.state.electionDetails);
  
  const header = {
    "Content-Type":"application/json",
    token:this.state.token
  };
  const prompt = `Read through the two json objects below (Candidates and Questions). Candidates object has details about every individual
   person that will be taking part in an election. The questions object has the users preferences on qualities that he is looking for in the candidate he wants to vote for, where an answer of 1 relates to highly disagree and 10 relates to completely agree. After reviewing the data for each candidate online, provide a json object that lists all of the candidates with the most suitable candidate being listed first and the lest suitable candidate being listed last. Each candidate object needs have the name of the candidate, the party that the candidate is from and a short statement about their campaign. questions=${questionAnswer} candidates=${resultBypoint} return the response in JSON formate with "mostSuitableCandidates" key. This key should have value with three key inside names are "name", "party", "campaignStatement". additionally also also return the elections details exactly as provided with "electionDetails" key inside the "mostSuitableCandidates" object. electionDetails = ${electionDetailsString}`
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getchatgptApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.chatgeptPrompt
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  const httpBody = {prompt}

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(httpBody)
);
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleResForchatgpt = async (from: string, message: Message) => {
  
  if (this.getchatgptApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const data = responseJson.response.mostSuitableCandidates
    setStorageData("chatgpt",JSON.stringify(data))
    setStorageData('electionResultsId', responseJson.election_result_id)

    this.setState({chatgptResponse:[...data], modalOPen: false,snackbarOpenRental:true} ,async() => {
      await this.getresultByPointApi()
      this.props.navigation.navigate('PrefferedCandidate')
    });
}};

handleResultsPageShareResponse = async (from :string, message: Message) => {
  if (this.apiCallResultsPageShare === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    const data = responseJson.chat_gpt_response.mostSuitableCandidates
    const data1 = responseJson.chat_gpt_response.electionDetails;

    setStorageData("chatgpt",JSON.stringify(data))
    setStorageData('electionResultsId', responseJson.election_result_id)

    this.setState({
      electionDetails: data1,
      chatgptResponse:[...data], modalOPen: false,snackbarOpenRental:true});
  }
}




  // Api response
  async receive(from: string, message: Message) {
    this.handleResForQuestionStats(from,message)
    this.handleResForQuestionStatsCategory(from,message)
    this.handleResForCreateAccount(from,message)
    this.handleResForresultByPoint(from,message)
    this.handleResForchatgpt(from,message)
    this.handleResultsPageShareResponse(from, message);
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (
        responseJson &&
        responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        this.getOrderIdFailureCallBack(responseJson);
      }

    
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        const data = responseJson.data.attributes;
        this.setState({
          successDataMsg: configJSON.successMessage,
          successData: "",
          isSubmitted: true,
          activeSteps: 2,
          first_name: data.first_name,
          last_name: data.first_name,
          email: data.first_name,
          phone_number: data.phone_number,
          selectedGender: data.gender,
          selectedCountry: data.country,
          selectedIndustry: data.industry,
          message: data.message,
          formikData: {
            selectedCountry: "1",
            selectedGender: "1",
            selectedIndustry: "education",
            first_name: "",
            last_name: "",
            email: "",
            selectedPhoneCountry: "",
            message: "",
            phone_number: "",
            countries: countrylist,
          },
        });
      }
    }
    // Customizable Area End
  }

  multiPageFormSubmit = async (values: CustomTypevalues) => {
    const selectedCountryLabel = this.state.country.filter((item: Country) => {
      return item.value === values.selectedCountry ? item : null;
    });
    const selectedGenderLabel = this.state.gender.filter((item: Gender) => {
      return item.value === values.selectedGender ? item : null;
    });
    const selectedIndustryLabel = this.state.industry.filter(
      (item: Industry) => {
        return item.value === values.selectedIndustry ? item : null;
      }
    );

    const rawData = {
      data: {
        attributes: {
          first_name: `${values.first_name}`,
          last_name: `${values.last_name}`,
          phone_number: `${values.selectedPhoneCountry} ${values.phone_number}`,
          email: `${values.email}`,
          gender: selectedGenderLabel[0].label,
          country: selectedCountryLabel[0].label,
          industry: selectedIndustryLabel[0].value,
          message: `${values.message}`,
        },
        type: "email_account",
      },
    };

    this.getOrderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      body: rawData,
      endPoint: "/bx_block_multipageforms/user_profiles",
    });
  };
  apiCall = async (data: CustomTypeData) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  stepOnSubmit = (values: CustomTypeSubmitvalues) => {
    this.setState({
      formikData: values,
      activeSteps: this.state.activeSteps + 1,
    });
  };

  handleBack = () => {
    this.setState({ activeSteps: this.state.activeSteps - 1 });
  };

  getSteps() {
    return ["Step 1", "Step 2", "Complete"];
  }

  LoginPage = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };

  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start

  handleChange = (event: any) => {
    if (this.state.selectedValue==''|| this.state.selectedValue!==event) {
      this.setState({ selectedValue: event });

    }
  };

  getQuizQuestion(isSimpleQuize: boolean) {
    if (isSimpleQuize) {
      const simpleQues: any[] = []
      this.state.allQuestions.map((ele: any) => {
        if (ele.attributes.sub_categories.length > 0) {
          simpleQues.push({ id: ele.attributes.id, name: ele.attributes.name, quest: ele.attributes.sub_categories[0] })
        }
      })
      this.setState({ simplequestions: simpleQues })
    } else {
      const detailsQuestions: any[] = []
      const categoryId = this.state.selectedBtn.filter((entry: any) => entry.clk).map((entry: any) => entry.id);

      this.state.allQuestions.map((ele: any) => {
        if (ele.attributes.sub_categories.length > 0) {
          if (categoryId.includes(String(ele.attributes.id))) {
            if (ele.attributes.sub_categories.length > 0) {
              ele.attributes.sub_categories.map((item: any) => {
                detailsQuestions.push({
                  id: ele.attributes.id,
                  name: ele.attributes.name,
                  quest: item
                });
              })
            }
          }
        }
      })
      this.setState({ detiledQuestions: detailsQuestions })
    }
  }
  navigationNumChange = () => {
    if (this.state.selectedValue !== '') {
      localStorage.removeItem('questionAnswer')
      if(this.state.selectedValue === 'Simplified Quiz'){
        this.getQuizQuestion(true)
        this.setState({snackbarOpenRental:false, navigationNum: 3 });

      }else{
        this.getQuizQuestion(false)
        this.setState({snackbarOpenRental:false, navigationNum: this.state.navigationNum + 1 });
      }
    }else{
      this.setState({snackbarOpenRental:true})
    }
  };
  handleSnackbarself=()=>{
    this.setState({snackbarOpenRental:false})
  }
  navigationNumChangeDec = () => {
    if(this.state.selectedValue === 'Simplified Quiz'){
      this.setState({ navigationNum: 0 });
      if(this.state.navigationNum == 0 && this.state.selectedValue=='Simplified Quiz'){
        this.props.navigation.navigate('ApiIntegration')
  
      } 
      
    }else
    if (this.state.navigationNum !== 0) {
      this.setState({ navigationNum: this.state.navigationNum - 1 });
    }else if(this.state.navigationNum == 0 && (this.state.selectedValue==''||this.state.selectedValue=='Simplified Quiz'||this.state.selectedValue=='Detailed Quiz')){
      this.props.navigation.navigate('ApiIntegration')

    } else {
      this.setState({ navigationNum: 0 });

    }

  };

  chngeQut = async() => {

    const savedData = JSON.parse(localStorage.getItem('questionAnswer') || '{}');
    const answers = savedData.answers || [];

    const newAnswer = {
      question: this.state.selectedValue === "Simplified Quiz"
        ? this.state.simplequestions[this.state.numQut]?.quest?.question
        : this.state.detiledQuestions[this.state.numQut]?.quest?.question,
      answer: this.state.sliderValue
    };

    answers.push(newAnswer);

    localStorage.setItem('questionAnswer', JSON.stringify({ answers }));
    
    if((this.state.selectedValue === 'Simplified Quiz' && this.state.numQut + 1 === this.state.simplequestions.length) || (this.state.selectedValue === 'Detailed Quiz' &&  this.state.numQut+1 === this.state.detiledQuestions.length )){
      this.setState({isCircularProgressIndicator: true});
      this.getresultByPointApi()
    } else {
      this.setState({sliderValue: 0, numQut: this.state.numQut + 1});
    }
  }

  chngePrvQut = () => {
    if (this.state.numQut !== 0) {
      this.setState({ numQut: this.state.numQut - 1 ,sliderValue: 0});
    } else {
      this.setState({ numQut: 0 });
    }
  }

  handleSliderChange = (event:any, newValue:any) => {
    this.setState({ sliderValue: newValue }); 
  };

  selectedBtnChange = (data: any) => {
    
    let isPresent = this.state.selectedBtnArray.includes(data);
    if(isPresent){
      this.setState({selectedBtnArray: this.state.selectedBtnArray.filter(item => item != data)});
    } else {
      if(this.state.selectedBtnArray.length < 5){
        this.setState({selectedBtnArray: [...this.state.selectedBtnArray, data]})
      } else {
        return;
      }
    }

    const updatedButtons = this.state.selectedBtn.map((cvl: any) => {      
      if (cvl.id === data) {
        return {
          ...cvl, 
          clk: !cvl.clk
        };
      }
      return cvl;
    });
    this.setState({
      selectedBtn: updatedButtons
    });
  };


  modalOpen=()=>{
    this.setState({ modalOPen: true});
      const fullURL = window.location;
      const electionResultsId = localStorage.getItem('electionResultsId')
      const pthUrl=`${fullURL}?shareId=${electionResultsId}`
      this.setState({pathUrl:pthUrl})
  }
  
  modalClose=()=>{
    this.setState({ modalOPen: false});
    
  }
  validateEmail(email:any) {
    const emailRegex = configJSON.Email_Regex;    
    if (emailRegex.test(email.target.value)) {
      this.setState({emailValidt:true,shareResutEmail:email.target.value})
    }else{
      this.setState({emailValidt:false})

    }
    
  }

  handleNavigateLandingPage = () => {
    this.props.navigation.navigate("LandingPage");
  }
  handelBackNavgtOnPr = () => {
    this.props.navigation.navigate("MultipageForms");
  }

  copyPath(){
    const fullURL = window.location;
    const electionResultsId = localStorage.getItem('electionResultsId')
    const pthUrl=`${fullURL}?shareId=${electionResultsId}`
    // this.setState({pathUrl: pthUrl});
    navigator.clipboard.writeText(pthUrl);
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<StateType, keyof StateType>);
    if (name === "zipcode") {
      const zipCodePattern = /^\d{5}(-\d{4})?$/;
      if (zipCodePattern.test(value)) {
        this.setState({ vaildZipcode: true });
      } else {
        this.setState({ vaildZipcode: false });
      }
    }
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    
    this.setState({ [name]: checked } as unknown as Pick<StateType, keyof StateType>);
  };


  zipCodeSubmt=async()=>{
    if(this.state.vaildZipcode && this.state.zipcode!==''){
      
        let data = await getStorageData("captcha_token")

        if (data) {
               if (this.state.validCapthcByBE&&this.state.vaildZipcode && this.state.zipcode!==''&&this.state.acknowledgeCheckbox && this.state.termsAndConditionCheckbox) {
                setStorageData("Zipcode",this.state.zipcode)
                this.props.navigation.navigate('ApiIntegration')
          }
          
          
          
        }
       
     
    }
   
  }

 
  // Customizable Area End
}
