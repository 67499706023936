export const crclr = require('../assets/crclr.png').default;
export const radZigImg = require('../assets/radZigImg.png').default;
export const whtDot = require('../assets/whtDot.png').default;
export const katorLogo = require('../assets/katorLogo.png').default;
export const textLogo = require('../assets/textLogo.png').default;

export const filledcheckbox = require('../assets/filledcheckbox.svg').default;
export const arrowbackward = require('../assets/arrowbackward.svg').default;
export const whitefullwave = require('../assets/whitefullwave.svg').default;
export const solidhalfcircle = require('../assets/solidhalfcircle.svg').default;
export const dotGrid4x6Image = require('../assets/4x6dotgrid.svg').default;
export const dotGrid6x6Image = require('../assets/6x6dotgrid.svg').default;
export const arrowForwardIcon = require('../assets/arrowforward.svg').default;
export const desktopArrForwIcon = require('../assets/whiteArrowForwIcon.png').default;
export const radoBtn = require('../assets/radoBtn.png').default;
export const clkRdoBtn = require('../assets/clkRdoBtn.png').default;

export const PreferdCandLogO = require('../assets/PreferdCandLogo.png').default;
export const prferdCnad = require('../assets/prferdCnad.png').default;
export const copy = require('../assets/copy.png').default;
export const copyLogo = require('../assets/copyLogo.png').default;

