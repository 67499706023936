export const menubarIcon = require('../assets/menubarIcon.svg').default;
export const katorlogo = require('../assets/katorLogo.png').default;
export const dotGridImage = require('../assets/6X6DotGrid.svg').default;
export const dotGrid5x6Image = require('../assets/5x6dotgrid.svg').default;
export const fullsolidcircle = require('../assets/fullCircle.svg').default;
export const halfsolidcircle = require('../assets/halfsolidcircle.svg').default;
export const halfwaveimage = require('../assets/halfwave.svg').default;
export const fullwaveimage = require('../assets/fullwave.svg').default;
export const verticalHalfCircle = require('../assets/verticalHalfCircle.svg').default;
export const arrowForwardIcon = require('../assets/arrowforward.svg').default;
export const footerArrForwIcon = require('../assets/footerArrowForwIcon.png').default;
export const footerkatorlogo = require('../assets/footerkatorlogo.svg').default;
export const iphone = require('../assets/iphone.png').default;