import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import { configJSON } from "./ContactusController";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { fullwaveimage } from "./assets";
import { InputBase } from "@mui/material";
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider>
        {/* Customizable Area Start */}
        <Box sx={styles.contactFormWrapper}>
          <Box component="img" src={fullwaveimage} alt="fullwaveimage" sx={styles.fullwaveImageBehindForm} />
          <Box sx={styles.contactFormContainer}>
            <Box sx={styles.formTitleContainer}>
              <Typography sx={styles.formtitle}>{configJSON.contactus}</Typography>
              <Typography sx={styles.formSubtitle}>{configJSON.contactusDescription}</Typography>
            </Box>
            <Box sx={styles.formContainer}>
              <Box sx={styles.groupInputWithLabel}>
                <Typography sx={styles.textlabel}>{configJSON.fullName}</Typography>
                <InputBase
                  name='fullname'
                  data-test-id='txtFullname'
                  sx={this.state.isFullNameBlank ? styles.textinputError : styles.textinput}
                  placeholder='John Smith'
                  value={this.state.fullname}
                  onChange={(e) => this.setFullName(e.target.value)}
                  autoComplete="off"
                />
                {
                  this.state.isFullNameBlank &&
                  <Typography
                    component="span"
                    className="error-fullname"
                    sx={styles.emptyFieldErr}
                  >{configJSON.fullNameBlankErrorMessage}
                  </Typography>
                }
                {this.state.fullnameErrorRes &&
                  <Typography
                    component="span"
                    className="error-fullname"
                    sx={styles.emptyFieldErr}
                  >{this.state.fullnameErrorRes}
                  </Typography>     
                }
              </Box>
              <Box sx={styles.groupInputWithLabel}>
                <Typography sx={styles.textlabel}>{configJSON.Email}</Typography>
                <InputBase
                  name='email'
                  data-test-id='txtEmail'
                  sx={this.state.isEmailBlank ? styles.textinputError : styles.textinput}
                  placeholder='name@email.com'
                  value={this.state.email}
                  onChange={(e) => this.setEmail(e.target.value)}
                  autoComplete="off"
                />
                {/* @ts-ignore */}
                {
                  this.state.isEmailBlank &&
                  <Typography
                    component="span"
                    className="error-email"
                    sx={styles.emptyFieldErr}
                  >
                    {configJSON.emailBlankErrorMessage}
                  </Typography>
                }
                {this.state.isEmailValid === false && this.state.email &&
                  <Typography
                    component="span"
                    className="error-email"
                    sx={styles.emptyFieldErr}
                  >
                    {configJSON.emailValidMessage}
                  </Typography>
                }
              </Box>
              <Box sx={styles.groupInputWithLabel}>
                <Typography sx={styles.textlabel}>{configJSON.contactusTextareaLabel}</Typography>
                <textarea
                  name="description"
                  data-test-id="txtDescription"
                  rows={3}
                  style={this.state.isDescriptionBlank ? styles.textareainputError : styles.textareainput
                  }
                  placeholder="You can write your questions here"
                  value={this.state.description}
                  onChange={(e) => this.setDescription(e.target.value)}
                  autoComplete="off"
                />
                <Box sx={styles.descriptionBotomBox}>
                  <Typography
                    component="span"
                    className="error-description"
                    sx={styles.emptyFieldErr}>
                    {this.state.isDescriptionBlank && configJSON.descriptionBlankErrorMessage}
                  </Typography>
                  <Typography
                    component="span"
                    className="character-count"
                    sx={styles.descriptionTextLength}
                  >{`${this.state.description.length}/50`}</Typography>
                </Box>
              </Box>
              <Box sx={{ ...styles.btnContainer, margin: "0px" }}>
                <Box
                  sx={styles.secondbtnContainer}
                  data-test-id="btnSubmit"
                  onClick={() => this.addQueryApi()}>
                  <Button
                    sx={{ ...styles.textBtn, color: "#F1ECE4" }}
                    endIcon={<ArrowForwardIcon sx={styles.forwArrIcon} />}
                  >
                    {configJSON.Send}
                  </Button>
                </Box>
                {
                  this.state.successMsg && (
                    <Box className="success-message" sx={styles.succesMsgBox}>
                      <Typography component="span"><CheckCircleIcon sx={styles.rightIcon} /></Typography>
                      <Typography component="span" sx={styles.successMessage}>{configJSON.successMessage}</Typography>
                    </Box>
                  )
                }
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Customizable End Start */}
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}

// Customizable Area Start
const styles = {
  succesMsgBox: {
    width: "50%",
    marginLeft: "25%",
    backgroundColor: "#FFFFFF",
    gap: "8px",
    padding: "7px 16px 7px 8px",
    borderRadius: "8px",
    display: 'flex',
    alignItems: 'center',
    position: "absolute",
    marginTop: "56px",
    top: "50%",
    left: "25%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    marginBottom: "5px",
    '@media (max-width: 660px)': {
      width: "94%",
      marginLeft: "25%",
      backgroundColor: "#FFFFFF",
      gap: "8px",
      padding: "7px 16px 7px 8px",
      borderRadius: "8px",
      display: 'flex',
      alignItems: 'center',
      position: "absolute",
      marginTop: "56px",
      top: "50%",
      left: "25%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
      marginBottom: "5px"
    },
  },
  successMessage: {
    fontWeight: "400",
    fontSize: "14px",
    marginTop: "3px",
    lineHeight: "24px",
    color: "#0F172A",
  },
  rightIcon: {
    width: "20px",
    height: "20px",
    color: "#34D399",
  },
  contactFormWrapper: {
    position: "relative" as const,
    width: "100%",
    height: "auto",
    paddingTop: "5rem",
  },
  fullwaveImageBehindForm: {
    position: "absolute" as const,
    width: "28%",
    height: "234px",
    left: "-10%",
    top: "85px",
    '@media (max-width: 660px)': {
      width: "240px",
      height: "164px",
      left: "-16px",
      top: "27px",
    },
  },
  contactFormContainer: {
    backgroundColor: "#706968",
    borderRadius: "40px 40px 0px 0px",
    padding: "50px 70px 10px 70px",
    position: "relative" as const,
    marginLeft: "16%",
    marginRight: "16%",
    zIndex: "1",
    '@media (max-width: 660px)': {
      padding: "50px 0px",
      margin: "0px"
    },
  },
  formTitleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "start",
    gap: "8px",
    padding: "8px 24px",
  },
  formtitle: {
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "40px",
    letterSpacing: "-0.005em",
    textAlign: "left" as "left",
  },
  formSubtitle: {
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    margin: "0px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "24px",
    padding: "24px 24px 0px",
  },
  groupInputWithLabel: {
    display: "flex",
    flex: "1",
    minWidth: "100%",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "4px",
  },
  textlabel: {
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  textinputError: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
  },
  textareainput: {
    width: "100%",
    height: "100px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    color: "#F1ECE4",
    border: "1px solid #F1ECE4",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    fontFamily: "sans-serif",
    fontSize: "16px",
    textAlign: "left" as "left",
    resize: "none" as "none",
  },
  textareainputError: {
    width: "100%",
    height: "100px",
    padding: "10px 8px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    outline: "none",
    opacity: "0px",
    backgroundColor: "transparent",
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "16px",
    textAlign: "left" as "left",
    resize: "none" as "none",
  },
  descriptionTextLength: {
    display: "flex",
    justifyContent: "end",
    color: "#F1ECE4",
    fontFamily: "sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "right" as "right",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "end",
    margin: "0px 16px",
    position: "relative",
    marginBottom: "50px",
    '@media (max-width: 660px)': {
      marginBottom: "0px",
    },
  },
  secondbtnContainer: {
    width: "156px",
    textAlign: "center",
    backgroundColor: "#6699B1",
    padding: "10px 16px",
    borderRadius: "8px",
    display: 'flex',
    justifyContent: 'center',
    "&:hover": {
      backgroundColor: '#55798a'
    },
    '@media (max-width: 660px)': {
      backgroundColor: "transparent",
      justifyContent: 'end',
      padding: "6px 0px",
      "&:hover": {
        backgroundColor: 'transparent'
      },
    },
  },
  forwArrIcon: {
    width: "24px",
    height: "22px",
    '@media (max-width: 660px)': {
      width: "42px",
      height: "42px",
    },
  },
  textBtn: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    border: "none",
    cursor: "pointer",
    color: "#524948",
    fontFamily: "sans-serif",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
    padding: "0px",
    textTransform: "capitalize",
    '@media (max-width: 660px)': {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: 'transparent'
      },
    },
  },
  emptyFieldErr: {
    color: "#F87171",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "Inter",
  },
  descriptionBotomBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  }
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Customizable Area End
