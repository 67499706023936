import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
  
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
//@ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  verified: boolean;
  //   recaptcha: ReCAPTCHA | null;
  key: string;
  token:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CaptchaController extends BlockComponent<Props, S, SS> {
  captcha: null;
  // Customizable Area Start
  captchaVerfy : string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.captcha = null;
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
      // Customizable Area End
    ];
    
    this.state = {
      // Customizable Area Start
      verified: false,
      key: configJSON.sitekey,
      token:''
      //@ts-ignore
      //recaptcha: React.createRef<ReCAPTCHA>(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForcaptchaVerfy(from,message)
    // Customizable Area End
  }

  // Customizable Area Start
  recaptchaRef(e: any) {
    //@ts-ignore
    this.captcha = e;
  }

  onVerify=async(recaptchaResponse: any) =>{
    this.setState({
      verified: true,
      token:await recaptchaResponse,
    });
    setStorageData("captcha_token", recaptchaResponse)

      this.googelcaptchaVerfy()


  }

  googelcaptchaVerfy = async() => {
    let response=await getStorageData("captcha_token")
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.captchaVerfy =requestMessage.messageId;;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.captchaVerfy+'?response='+response
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   return true
  }
  
  handleResForcaptchaVerfy = async (from: string, message: Message) => {
  if (
    this.captchaVerfy ===    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

  ) {
   
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    setStorageData("captcha_token", responseJson.success)


    
  }
  };

  reset() {
    //@ts-ignore
    this.captcha.reset();
  }
  // Customizable Area End
}
