Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.POstMathod = "POST";

exports.exampleAPiEndPoint = "https://jsonplaceholder.typicode.com/todos/1";
exports.exampleAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ApiIntegration Excample";
exports.labelBodyText = "Click Button to Demo ApiIntegration";
exports.btnExampleTitle = "CLICK ME";

exports.homeButton = "Home";
exports.startSurveyButton = "Start Survey";
exports.electionContent = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporsks...";
exports.nextElectionTitle = "Next elections";
exports.bellotpdyaElctonDAtaGetEndpoint = "bx_block_apiintegration52/ballotpedias/election_dates_by_point";


// Customizable Area End