Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "bx_block_contact_us/contacts";
exports.createAccountApiEndPoint = "account_block/accounts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.Send = "Send";
exports.contactus = "Contact Us";
exports.contactusDescription = "Fill out this form if you wish to contact us";
exports.fullName = "Full Name";
exports.Email = "Email";
exports.contactusTextareaLabel = "What would you like to ask about?";
exports.fullNameBlankErrorMessage = "Please enter your full name.";
exports.emailBlankErrorMessage = "Please enter a email address.";
exports.descriptionBlankErrorMessage = "Please provide a description of the issue or inquiry.";
exports.emailValidMessage = "Please enter a valid email address."
exports.successMessage = "Your message was sent successfully."
// Customizable Area End