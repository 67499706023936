import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@mui/material";
import { katorlogo, footerkatorlogo, menubarIcon, verticalHalfCircle } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Box>
          {
            this.props.showHeaderOnly &&
            <Box sx={styles.container}>
              <Box sx={styles.innerContainer}>
                <Box sx={styles.desktopHeader} data-test-id="desktop-header">
                  {
                    this.props.isLandingPageShowLogo &&
                    <Box sx={styles.logoContainer}>
                      <img style={styles.katorlogo} src={katorlogo} alt="katorlogo" data-test-id="logo" />
                    </Box>
                  }
                  <Box sx={styles.headerItem}>
                    <Link style={styles.navItemforMultipageforms} to={"/Multipageforms"} data-test-id="take-quiz-desktop">Take the quiz</Link>
                    {this.props.isLandingPageShowLogo ?
                      <a style={styles.navItem}
                        href="#aboutUs"
                        data-test-id="about-us-desktop">About Us</a>
                      :
                      <Link style={styles.navItem}
                        to={"/#aboutUs"}
                        data-test-id="about-us-desktop">About Us</Link>
                    }
                    {this.props.isLandingPageShowLogo ?
                      <a style={styles.navItem}
                        href={"#contactUs"}
                        data-test-id="contact-us-desktop">Contact Us</a>
                      :
                      <Link style={styles.navItem}
                        to={"/#contactUs"}
                        data-test-id="contact-us-desktop">Contact Us</Link>
                    }
                  </Box>
                  {
                    this.props.isLandingPageShowLogo &&
                    <Box sx={styles.circleImgHead}>
                      <img src={verticalHalfCircle} alt="" />
                    </Box>
                  }
                </Box>
                {
                  this.state.isOpenMenu === true ? "" :
                    <Box sx={styles.mobileMenuIcon} data-test-id="mobile-menu-icon"  >
                      <img src={menubarIcon} alt="menubarIcon" onClick={this.taggleMenuIcon} />
                    </Box>
                }
                {
                  this.state.isOpenMenu &&
                  <Box data-test-id="mobile-menu">
                    <Box sx={styles.menuContainer}>
                      <Typography component="span" sx={styles.menuSize}>MENU</Typography>
                    </Box>
                    <Box sx={styles.closeButton}>
                      <Box onClick={this.taggleMenuIcon}><CloseIcon sx={styles.closeIcon} data-test-id="close" /></Box>
                    </Box>
                    <Box sx={styles.menuHead}>
                      <Box sx={styles.menuSubHead}>
                        <Box onClick={() => this.handleOnClickAboutUs()} sx={styles.menuItemHead} data-test-id="about-us-mobile">
                          {
                            this.props.isLandingPageShowLogo ?
                              <a style={styles.menuText} href={"#aboutUs"}
                                data-test-id="about-us-moblie">About Us</a>
                              :
                              <Link style={styles.menuText} to={"/#aboutUs"}
                                data-test-id="about-us-moblie">About Us</Link>
                          }
                        </Box>
                        <Box sx={styles.menuItemHead} data-test-id="take-quiz-mobile">
                          <Link style={styles.menuText} to="/Multipageforms">Take the quiz</Link>
                        </Box>
                        <Box onClick={() => this.handleOnClickContactUs()} sx={styles.menuItemHead} data-test-id="contact-us-mobile"  >
                          {
                            this.props.isLandingPageShowLogo ?
                              <a style={styles.menuText} href={"#contactUs"}
                                data-test-id="contact-us-moblie">Contact Us</a>
                              :
                              <Link style={styles.menuText} to={"/#contactUs"}
                                data-test-id="contact-us-moblie">Contact Us</Link>
                          }
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={styles.fixedFooter}>
                      <Typography onClick={() => this.handleNavgateTarmsAndCondition()} sx={styles.termsText} data-test-id="terms-conditions">Terms and Conditions</Typography>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          }
        </Box>
        <Box>
          {
            this.props.showFooterOnly &&
            <Box sx={styles.footerContainer} data-test-id="footer">
              <Box sx={styles.footerContent}>
                <Box sx={styles.lefttext}>
                  <Box component="span" sx={styles.leftfooterText}>
                    <img style={styles.footerkatorlogo} src={footerkatorlogo} alt="kator" />
                  </Box>
                  <Box component="span" sx={styles.leftfooterText} data-testid="privacy-policy" onClick={() => this.handleTermAndCondition()}>{configJSON.privacypolicytext}</Box>
                </Box>
                <Box sx={styles.righttext}>
                  <Box component="span" sx={styles.rightfooterText}>{configJSON.katortext}</Box>
                  <Box component="span" sx={styles.rightfooterText} data-testid="terms-link" onClick={() => this.handleTermAndCondition()}>{configJSON.termsconditionstext}</Box>
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    width: "100%",
    backgroundColor: "#F1ECE4",
    zIndex: 999,
    top: 0,
    '@media (max-width: 660px)': {
      position: "fixed",
    },
  },
  innerContainer: {
    alignItems: "center",
    backgroundColor: "#F1ECE4",
  },
  desktopHeader: {
    display: { xs: 'none', sm: 'flex' },
    width: "100%",
    height: "80px",
    paddingTop: "10px",
    alignItems: "center",
  },
  logoContainer: {
    position: "absolute",
    zIndex: 1,
    display: "flex",
    justifyContent: "space-around",
    marginLeft: { lg: "120px", md: '120px', sm: "70px" },
    alignItems: "center",
  },
  headerItem: {
    // display: "flex",
    // justifyContent: "space-around",
    // alignItems: "center",
    width: "100%",
    textAlign: "center",
  },
  navItemforMultipageforms: {
    padding: "0px 60px",
    fontSize: "16px",
    color: "#524948",
    fontFamily: "Inter",
    fontWeight: 700,
    textDecoration: "none",
    '&:hover': {
      color: 'black',
    },
    '@media (max-width: 860px)': {
      marginLeft: "100px",
    },
    '@media (max-width: 660px)': {
      marginLeft: "40px",
    },
  },
  navItem: {
    padding: "0px 60px",
    fontSize: "16px",
    color: "#948E8D",
    fontFamily: "Inter",
    fontWeight: 600,
    textDecoration: "none",
    '&:hover': {
      color: 'black',
    },
    '@media (max-width: 860px)': {
      marginLeft: "100px",
    },
    '@media (max-width: 660px)': {
      marginLeft: "40px",
    },
  },
  circleImgHead: {
    zIndex: "1",
    position: "absolute",
    right: "210px",
    transform: "rotate(270deg)",
    marginTop: "-16px"
  },
  mobileMenuIcon: {
    display: { xs: 'flex', sm: 'none' },
    marginLeft: "8px",
  },
  menuContainer: {
    textAlign: "center",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "space-around",
  },
  menuText: {
    fontSize: "26px",
    color: "#524948",
    // marginTop: "-20px",
    fontWeight: 400,
    lineHeight: "40px",
    cursor: "pointer",
    textDecoration: "none",
    '&:hover': {
      color: 'black',
      borderBottom: "2px solid black",
      fontWeight: 600,
      fontSize: "22px",
    },
  },
  closeButton: {
    zIndex: "1",
    position: "absolute",
    top: "9.5px",
    right: "20px",
  },
  fixedFooter: {
    position: "fixed",
    bottom: "10px",
    textAlign: "center",
    left: "50%",
    transform: "translateX(-50%)",
    borderBottom: "1px solid black",
    cursor: "pointer"
  },
  termsText: {
    marginBottom: "-5px",
  },
  katorlogo: {
    width: "150px",
    height: "50px"
  },
  menuSize: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    color: "#111827"
  },
  closeIcon: {
    height: "21px",
    width: "21px"
  },
  menuHead: {
    display: "flex",
    alignItems: "center",
    height: "100vh"
  },
  menuSubHead: {
    width: "100%",
    textAlign: "center"
  },
  menuItemHead: {
    flexDirection: "column",
    margin: "20px 0px"
  },
  footerContainer: {
    backgroundColor: "#524948",
    height: "110px",
    alignItems: "center",
    display: "flex",
    zIndex: "999",
    position: "relative",
    width: "100%",
    '@media (max-width: 660px)': {
      alignItems: "center",
      display: "flex",
      height: "80px",
    }
  },
  footerContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "86%",
    alignItems: "center",
    margin: "auto",
    '@media (max-width: 660px)': {
      width: "86%",
    }
  },
  lefttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  righttext: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
  },
  footerkatorlogo: {
    width: "auto",
    height: "28px",
    "@media (max-width: 575px)": {
      width: "auto",
      height: "20px",
    }
  },
  leftfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    cursor: "pointer",
    textAlign: "left" as "left",
    "@media (max-width: 575px)": {
      color: "#F1ECE4",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "left" as "left",
    }
  },
  rightfooterText: {
    color: "#F1ECE4",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    cursor: "pointer",
    textAlign: "right" as "right",
    "@media (max-width: 575px)": {
      color: "#F1ECE4",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      textAlign: "right" as "right",
    }
  }
};

// Customizable Area End
