import React from "react";

// Customizable Area Start
import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import { Dialog, CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";
import { waveLines, vote, redCircle, dotGroup, blueCircle, blackWaveDotGroup, blackWaveGroup } from "./assets"
// Customizable Area End

import ApiIntegrationController, {
  Props,
  configJSON,
} from "./ApiIntegrationController";

// Customizable Area Start

// Customizable Area End

export default class ApiIntegration extends ApiIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>

      {this.state.isCircularProgressIndicator ? <Dialog open={this.state.isCircularProgressIndicator} PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      overflow:'hidden'
                    }
                  }}>
                <CircularProgress size={120} sx={{color: '#6699B1'}}/>            
                </Dialog> : <></>}
        { this.state.isShowElectionData && (
          <Box  sx={{
            ...(this.state.isShowLimitAndNotFoundElection ? styles.showOrNot : {}),
          }}>
            
            <Box sx={styles.isshowMobile}>
              <NavigationMenuWeb      
                navigation={this.props.navigation}
                id={this.props.id}
                showHeaderOnly={true}
                isLandingPageShowLogo={false}
              />
            </Box>



            <Box sx={styles.headWrapperElectionResult} data-testid='election-data'>
              <Box sx={styles.backArrowContainer}>
                <ArrowBackIcon sx={styles.backArrowIcon}  onClick={() => this.handleBackArrow()}/>
              </Box>
              <Box sx={styles.waveLinesContainer}>
                <Box sx={styles.subwaveLinesContainer}>
                  <img style={styles.waveLines} src={waveLines} alt="vector image" />
                </Box>
              </Box>


              <Box sx={styles.headElectionDetials}>

{this.state.electionData.map((cvl:any)=>{
  return<>
  <Box sx={styles.subHeadElectionDetials}>
                  
                  <Box sx={styles.nextElectionDetail}>
                    <Typography variant="h5" sx={styles.nextElectionTitle}>{configJSON.nextElectionTitle}</Typography>
                    <Typography variant="h3" sx={styles.electionDate}>{cvl.date}</Typography>
                  </Box>
{cvl.elections.map((Cvl:any)=>{
  return<>
  <Box sx={styles.electionsDate}>
                    <Box sx={styles.electionDateContainer}>
                      <Box component="span" sx={styles.voteImageHead}>
                        <img src={vote} alt="voting image" />
                      </Box>
                      <Box component="span" sx={styles.electionDetails}>
                        <Typography variant="body2" sx={styles.electionName}>{Cvl.type}</Typography>
                        <Typography variant="body1" sx={styles.electionNameDetail}>{Cvl.description}</Typography>
                      </Box>
                    </Box>
                  </Box>
  </>
})}
                  
                </Box>
  </>
})}
              
              </Box>
              <Box sx={styles.surveyButtomHead} >
                <Button sx={styles.surveyButton} data-testid='start-survey' onClick={() => this.handleNavigateOnQuiz()}>{configJSON.startSurveyButton}<ArrowForwardIcon sx={styles.forwardIcon} /></Button>
              </Box>
              <Box sx={styles.redCircleHead}>
                <img style={styles.redCircle} src={redCircle} alt="rd circle image" />
              </Box>
              <Box sx={styles.blueCircleHead}>
                <img style={styles.blueCircle} src={blueCircle} alt="rd circle image" />
              </Box>
              <Box sx={styles.dotGroupHead}>
                <Box sx={styles.dotSubGroupImage}>
                  <img style={styles.dotGroupImage} src={dotGroup} alt="rd circle image" />
                </Box>
              </Box>
            </Box>
            <Box sx={styles.isshowMobile}>
              <NavigationMenuWeb      
                navigation={this.props.navigation}
                id={this.props.id}
                showFooterOnly={true}
              />
            </Box>
          </Box>
          )} 
          {

          (this.state.isShowLimit || this.state.isNotFoundElection) && (
            <Box>
              <Box sx={styles.isshowMobile}>
                <NavigationMenuWeb      
                  navigation={this.props.navigation}
                  id={this.props.id}
                  showHeaderOnly={true}
                  isLandingPageShowLogo={false}
                />
              </Box>
              <Box sx={styles.headWrapperOut} data-testid='election-data'>
                <Box sx={styles.backArrowContainer}>
                  <ArrowBackIcon sx={styles.backArrowIcon} data-test_id='backButton ' onClick={() => this.handleBackArrow()} />
                </Box>
                <Box sx={styles.headBlackWaveGroup}>
                  <Box sx={styles.subHeadBlackWaveGroup}>
                    <img style={styles.blackWaveGroup} src={blackWaveGroup} alt="vector image" />
                  </Box>
                </Box>
                <Box sx={styles.headBlackWaveDotGroup}>
                  <Box sx={styles.subHeadBlackWaveDotGroup}>
                    <img style={styles.blackWaveDotGroup} src={blackWaveDotGroup} alt="blackWaveDotGroup image" />
                  </Box>
                </Box>
                <Box sx={styles.headLimitOver}>
                  <Box sx={styles.subHeadLimitOver}>
                    {
                      this.state.isShowLimit &&
                      <Typography variant="body2" sx={styles.limitOverContent} data-testid="service-limit" >
                        Our service is at its usage limit right now, please check back later
                      </Typography>
                    }
                    {
                      this.state.isNotFoundElection &&
                      <Typography variant="body2" sx={styles.electionNotFoundContent }  data-testid="no-elections">
                        We could not find any elections in your area
                      </Typography>
                    }
                  </Box>
                  <Box sx={styles.limitOverButtonHead}>
                    <Button sx={styles.limitOverButton} onClick={() => this.handleBackToHome()} data-testid="home-button">
                      {configJSON.homeButton} <ArrowForwardIcon sx={styles.forwardIconLimitOver} />
                    </Button>
                  </Box>
                </Box>
                <Box sx={styles.limitOverOuterButtonHead}>
                  <Button sx={styles.limitOverOuterButton} onClick={() => this.handleBackToHome()} data-testid="home-button-mobile">
                    {configJSON.homeButton} <ArrowForwardIcon sx={styles.forwardIconOuterLimitOver} />
                  </Button>
                </Box>
                <Box sx={styles.limitoverRedCircleHead}>
                  <img style={styles.limitoverRedCircle} src={redCircle} alt="vector image" />
                </Box>
              </Box>
              <Box sx={styles.isshowMobile}>
                <NavigationMenuWeb      
                  navigation={this.props.navigation}
                  id={this.props.id}
                  showFooterOnly={true}
                />
              </Box>
            </Box>
          )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  showOrNot:{
    display: "none"

  },
  isshowMobile: {
    display: "block",
    '@media (max-width: 660px)': {
      display: "none"
    }
  },
  headWrapperElectionResult: {
    position: "relative",
    backgroundColor: "#F1ECE4",
    width: "100%",
    height:'100%'
    ,'@media (max-width: 375px)': {
      height:'100vh'
    }
  },
  backArrowContainer: {
    padding: "16px",
    position: "absolute",
    left: "128px",
    top: "28px",
    zIndex: "999",
    backgroundColor: "#F1ECE4",
    '@media (max-width: 660px)': {
      width: "100%",
      height: "48px",
      marginTop: "0px",
      top: "0px",
      left: "0px",
      padding: "0px",
    },
  },
  backArrowIcon: {
    height: "32px",
    width: "32px",
    '@media (max-width: 660px)': {
      left: "8px",
      padding: "10px 12px",
      height: "24px",
      width: "24px",
    },
  },
  waveLinesContainer: {
    position: "absolute",
    top: "-55px",
    right: "0px",
    zIndex: "0",
    '@media (max-width: 660px)': {
      top: "118px",
      right: "-10px",
    },
  },
  subwaveLinesContainer: {
    width: "370px",
    height: "260px",
    '@media (max-width: 660px)': {
      width: "240px",
      height: "160px",
    },
  },
  waveLines: {
    width: "100%",
    height: "100%",
  },
  headElectionDetials: {
    width: "604px",
    margin: "auto",
    gap: "32px",
    '@media (max-width: 660px)': {
      paddingTop: "10px",
      width: "90%",
      marginLeft: "5%"
    },
  },
  subHeadElectionDetials: {
    paddingTop: "32px",
  },
  nextElectionDetail: {
    padding: "8px 0px 4px 0px",
    gap: "8px",
    '@media (max-width: 660px)': {
      position: 'relative',
      zIndex: 1,
    },
  },
  nextElectionTitle: {
    margin: "8px 0px",
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#948E8D',
  },
  electionDate: {
    margin: "8px 0px",
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    color: '#524948',
  },
  electionsDate: {
    marginTop: "16px",
  },
  electionDateContainer: {
    padding: '16px 12px 16px 12px',
    backgroundColor: "#F1ECE4",
    marginBottom: "24px",
    borderRadius: '6px',
    border: '1px solid #524948',
    display: "flex",
    color: '#F1ECE4',
    zIndex: "999",
    position: "relative",
  },
  voteImageHead: {
    width: "30px",
    height: "30px",
  },
  electionDetails: {
    marginLeft: "8px",
  },
  electionName: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#524948',
  },
  electionNameDetail: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#706968',
  },
  surveyButtomHead: {
    marginTop: "40px",
    textAlign: "center",
    '@media (max-width: 660px)': {
      display: "flex",
      marginTop: "20px",
      justifyContent: "end",
      width: "90%",
      marginLeft: "5%",
    },
  },
  surveyButton: {
    padding: '8px 16px ',
    backgroundColor: '#6699B1',
    color: '#F1ECE4',
    borderRadius: '8px',
    alignItems: 'center',
    width: '236px',
    height: '56px',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    fontFamily: 'Inter',
    textTransform: "capitalize",
    marginBottom: "0px",
    '&:hover': {
      backgroundColor: '#6699B1',
      cursor: 'pointer',
    },
    '@media (max-width: 660px)': {
      padding: '0px',
      marginBottom: "42px",
      backgroundColor: '#F1ECE4',
      color: '#524948',
      alignItems: 'center',
      width: '148px',
      height: '50px',
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: 'Inter',
      textTransform: "capitalize",
      '&:hover': {
        backgroundColor: '#F1ECE4',
        cursor: 'pointer',
      },
    },
  },
  forwardIcon: {
    marginLeft: "6px",
    height: "24px",
    width: "24px",
    '@media (max-width: 660px)': {
      marginLeft: "20px",
      height: "35px",
      width: "35px",
    }
  },
  redCircleHead: {
    position: 'absolute',
    bottom: '10px',
    left: "0px",
    '@media (max-width: 660px)': {
      display: 'none',
    },
  },
  redCircle: {
    width: '201px',
    height: '262px',
  },
  dotGroupHead: {
    position: 'absolute',
    bottom: '150px',
    left: "83px",
    '@media (max-width: 660px)': {
      bottom: '260px',
      left: "-27px"
    },
  },
  dotSubGroupImage: {
    width: '171px',
    height: '171px',
    '@media (max-width: 660px)': {
      width: '100px',
      height: "100px",
    },
  },
  dotGroupImage: {
    width: "100%",
    height: "100%"
  },
  blueCircleHead: {
    visibility: "hidden",
    '@media (max-width: 660px)': {
      position: 'absolute',
      left: "0px",
      bottom: '0px',
      visibility: "visible",
      zIndex: "0"
    },
  },
  blueCircle: {
    width: '124px',
    height: '124px',
  },
  headWrapperOut: {
    position: "relative",
    backgroundColor: "#F1ECE4",
    width: "100%",
    height: "100vh",
  },
  headBlackWaveGroup: {
    position: 'absolute',
    top: '-45px',
    right: "-25px",
    zIndex: "0",
    '@media (max-width: 375px)': {
      display: "none",
    },
  },
  subHeadBlackWaveGroup: {
    width: '460px',
    height: '210px',
  },
  blackWaveGroup: {
    width: "100%",
    height: "100%",
  },
  headBlackWaveDotGroup: {
    display: "none",
    '@media (max-width: 660px)': {
      display: "block",
      position: 'absolute',
      top: '150px',
      right: "-25px",
      zIndex: "0",    marginTop:'-25%'

    },
  },
  subHeadBlackWaveDotGroup: {
    width: '380px',
    height: '190px',
  },
  blackWaveDotGroup: {
    width: "100%",
    height: "100%",marginTop:'-1%'
  },
  headLimitOver: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    '@media (max-width: 660px)': {
      width: "100%",
      marginLeft: "0%",
    },
  },
  subHeadLimitOver: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 2,
  },
  limitOverContent: {
    padding: "8px 28px",
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.5px',
    color: '#948E8D',
    fontWeight: 400,
    fontFamily: 'Inter',
    width: "75%",
  },
  electionNotFoundContent: {
    padding: "8px 28px",
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '-0.5px',
    color: '#948E8D',
    fontWeight: 400,
    fontFamily: 'Inter',
    width: "95%",
  },
  limitOverButtonHead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: "10px",
    '@media (max-width: 660px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  limitOverButton: {
    padding: '10px 16px',
    backgroundColor: 'rgb(102, 153, 177)',
    color: 'rgb(241, 236, 228)',
    borderRadius: '8px',
    alignItems: 'center',
    width: '200px',
    height: '46px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '28px',
    fontFamily: 'Inter',
    textTransform: 'capitalize',
    '@media (max-width: 660px)': {
      display: "none",
    },
    '&:hover': {
      backgroundColor: 'rgb(82, 133, 157)',
      cursor: 'pointer',
    },
  },
  forwardIconLimitOver: {
    height: '24px',
    width: '24px',
    marginLeft: '5px',
    '@media (max-width: 660px)': {
      height: '50px',
      width: '50px',
      marginLeft: '25px',
    },
  },
  limitOverOuterButtonHead: {
    display: "none",
    '@media (max-width: 660px)': {
      display: "block",
      backgroundColor: "#F1ECE4",
      paddingBottom: "25px",
      paddingRight: "10px",
      margin: 'auto',
      textAlign: "end",

    }
  },
  limitOverOuterButton: {
    '@media (max-width: 660px)': {
      padding: '0px',
      marginBottom: "0px",
      backgroundColor: '#F1ECE4',
      color: '#524948',
      alignItems: 'center',
      width: '120px',
      height: '50px',
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: 'Inter',
      textTransform: "capitalize",
    },
    '&:hover': {
      backgroundColor: '#F1ECE4',
      cursor: 'pointer',
    },
  },
  forwardIconOuterLimitOver: {
    height: '24px',
    width: '24px',
    marginLeft: '5px',
    '@media (max-width: 660px)': {
      height: '50px',
      width: '50px',
      marginLeft: '25px',
    },
  },
  limitoverRedCircleHead: {
    position: 'absolute',
    bottom: '-60px',
    left: "-20px",
    '@media (max-width: 660px)': {
      display: 'none',
    },
  },
  limitoverRedCircle: {
    width: '171px',
    height: '212px',
  }
}
// Customizable Area End
