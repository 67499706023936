import React from "react";

import {
  Box,
  Button,  
  // Customizable Area Start
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  Dialog,
  CircularProgress
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slider from '@mui/material/Slider';
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";


import { radZigImg, whtDot, crclr, katorLogo, textLogo, radoBtn, clkRdoBtn }from "./assets";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Customizable Area End

import MultipageFormsController, {
  Props,
 
} from "./MultipageFormsController";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";



export default class DetailedQuiz extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  reusabelContent=(marks:any)=>{
    return<>
    <div className="mainFormPageQuz questionSectn QunClas" >
                <div className="container">
                  <div className="topBacckButton Component" data-test-id='backButtonOnTh'><button data-test-id='backNavMnuQ' onClick={this.navigationNumChangeDec} ><ArrowBackIcon className="lftarow" /></button></div>
                  <div className=" QuzAllCont">
  
                    <div className="showOnMoblorDektLogo">
                      <img src={katorLogo} alt="logo" />
                    </div>
                    <div className="quzeModel" id="quzeModel">
                      <div className="logoTxtQuSkp" id="Katorlogo">
                        <div className="textLogo">
                          <img src={textLogo} alt="logo" className="textlogo"/>
                        </div>
                        <span className="logoTxtQuSkpOne logoTxt" id="logoTxt">{this.state.simplequestions[this.state.numQut]?.name}</span>
                        <span className="logoTxtQuSkpSec logoQut">Question {this.state.numQut + 1}/15</span>
                      </div>
  
                      <div className="quzeQ nextquestionButont" id="quze">
                        <h3>{this.state.simplequestions[this.state.numQut]?.quest?.question}</h3>
                        <span className="votFrQt votcont" id="dummyVot">1 (disagree) - 10 (agree)</span></div>
                      <div className="votSelecSld valuChange">
                        <Box sx={{ width: 300, color: '#DFD5C4' }}>
                          <Slider
                          className="nextquestionButontt"
                            aria-label="Always visible"
                            value={this.state.sliderValue}
                            data-test-id='simpleQUeston'
                            onChange={this.handleSliderChange}
                            marks={marks}
                            min={1}
                            max={10}
                            valueLabelDisplay="on"
                            sx={{
                             
                              "& .MuiSlider-valueLabel": {
                                backgroundColor: '#F1ECE4', color: '#524948', fontFamily: 'Inter',fontSize:'22px',fontWeight:'400',
                                " @media(max-width:768px)": {fontSize:'20px',fontWeight:'400',
                                }
                              },
                              "& .MuiSlider-markLabel": {
                                display: 'none'
                              },
                               "& .MuiSlider-rail": {
                                backgroundColor: '#DFD5C4', height: '17px', color: '#FFFFFF'
                              },
                              
                               "& .MuiSlider-thumb ": {
                                backgroundColor: '#DFD5C4', boxShadow: '#DFD5C4'
                              },
                              "& .MuiSlider-track": {
                                backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', height: '17px'
                              },
                              "& .MuiSlider-thumb:hover": {
                                color: '#DFD5C4', boxShadow: '#DFD5C4'
                              },
                               "& .MuiSlider-root": {
                                backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', color: '#DFD5C4', height: '17px'
  
                              },
                              
                            }
                            }
                          />
                        </Box>
                      </div>
                      <div className="prvNxtBtn nextquestionButon" id="prvNxtBtn">
                        <button type="button" data-test-id="chngePrvQut" onClick={this.chngePrvQut} className="nextquestionButon">
                          <WestIcon  className="prvNxtBtnb" />
                        </button>
                        <button type="button" id="buttonChngQutn" data-test-id="chngeQut" onClick={this.chngeQut}>
                          <EastIcon  className="prvNxtBtnb nextquestionButon" />
                        </button>
                      </div>
   
                    </div>
  
  
                  </div>
                </div>
  
              </div>
    </>
  }
  // Customizable Area End

  render() {
    const marks = [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 7,
        label: '7',
      },
      {
        value: 8,
        label: '8',
      },
      {
        value: 9,
        label: '9',
      }, {
        value: 10,
        label: '10',
      },

    ];

    

    return (
      // Customizable Area Start
      <>
        <StyledWrapper>

          {this.state.isCircularProgressIndicator ? <Dialog open={this.state.isCircularProgressIndicator} PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow:'hidden'
              }
            }}>
          <CircularProgress size={120} sx={{color: '#6699B1'}}/>            
          </Dialog> : <></>}

          {this.state.isErrorModalOpen ? <Dialog open={this.state.isCircularProgressIndicator} PaperProps={{
              style: {
                boxShadow: 'none',
                overflow:'hidden',
                height:'30%',
                width:'50%',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
              }
            }}>
          <h3>No Candidate found</h3>           
          </Dialog> : <></>}
       

          {this.state.navigationNum == 0 &&
                 
            <div className="mainFormPage" >
              <div className="radZigImg">
                <img src={radZigImg} />
              </div>
              <div className="whtdts">
                <img src={whtDot} />
              </div>
              <div className="crcle">
                <img src={crclr} />
              </div>
              <div className="hedrSet">
                <NavigationMenuWeb
                  navigation={this.props.navigation}
                  id={this.props.id}
                  showHeaderOnly={true}
                  isLandingPageShowLogo={false}/>
              </div>
 
              <div className="container topContnr" id="topBacckButton">
                <div className="topBacckButton topbackmnuBtn"><button  data-test-id='backNavMnu' className="backNavMnu" onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow" /></button></div>
                <div className="childCompont ">

                  <div className="howWouldAndPera pera">

                    <div className="howWouldHead heading">
                      <h2> How would you like to get recommended candidates? </h2>

                    </div>
                    <div className="howWouldPera ">
                      <p>we'll ask you a few questions to generate a list of recommended candidates. you can choose between a quick quiz for faster results or a detailed quize for the most accurate recommendations."Blod the words "fast results" and accurate recommendations </p>
                    </div>
                  </div>
                  <div className="quizeType ">

                
                    <div className="radioCustom">
                      <div className={this.state.selectedValue === 'Simplified Quiz'?'backColor':"manContRado"}  data-test-id='radiobtnchange1' onClick={()=>{this.handleChange('Simplified Quiz')}} 
>
                        <div className={this.state.selectedValue === 'Simplified Quiz'?'manContRadoClk':"manContRdFt"} >
                          <span><img src={this.state.selectedValue === 'Simplified Quiz'?clkRdoBtn:radoBtn} alt="Logo" width='20px' height='20px'/>
                          </span>
                           Simplified Quiz
                        </div>
                        It will take just 5 minutes.
                      </div>
                      <div className={this.state.selectedValue === 'Detailed Quiz'?'backColor':"manContRado"} data-test-id='radiobtnchange2' onClick={()=>{this.handleChange('Detailed Quiz')}}
>
                        <div className={this.state.selectedValue === 'Detailed Quiz'?'manContRadoClk':"manContRdFt"} >
                          <span><img src={this.state.selectedValue === 'Detailed Quiz'?clkRdoBtn:radoBtn} alt="Logo" width='20px' height='20px'/>
                          </span>
                          Detailed Quiz                        </div>
                        It will take 15 minutes.
                      </div>
                    </div>
                  </div>
                  {
                    ResolveCondition(this.state.snackbarOpenRental, <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                     }} 
                     data-test-id='snkBAr'
                     open={this.state.snackbarOpenRental}
                      autoHideDuration={3000} 
                     onClose={this.handleSnackbarself} >
                      <Alert variant="filled" severity="error" 
                      sx={{
                        fontSize:'17px',fontWeight:'700'
                      }}>
                      Please Select The Quiz Type.</Alert>
                  </Snackbar>,'')
                  }
                  <div className="contnuBtn"  id="CountBtnT" >
                    <Button variant="contained" data-test-id='hideOnMobl' className="hideOnMobl" onClick={this.navigationNumChange} sx={{textTransform:'capitalize'}} >Continue <EastIcon className="lftTrght" />
                    </Button>
                    <Button variant="text" data-test-id='showOnMobl' onClick={this.navigationNumChange} sx={{textTransform:'capitalize',
                   display:'none',
    "@media(max-width:768px)": {
      color:'#524948',fontSize:'16px',fontWeight:'700',margin:'25% -50% 20px 0',display:'flex',justifyContent:'center',alignItems:'center',columnGap:'40px'
    }
                      
                    }}
                    >Continue <EastIcon />
                    </Button>

                  </div>
                </div>
              </div>
              <div className="ftrSet">
              <NavigationMenuWeb      
            navigation={this.props.navigation}
            id={this.props.id}
            showFooterOnly={true}
            isLandingPageShowLogo={false}
          />
              </div>
              
            </div>
            
          }
          {this.state.navigationNum == 1 && <div className="mainFormPageSecond"  >
            <div className="radZigImg">
                <img src={radZigImg} />
              </div>
              <div className="whtdts whtdt">
                <img src={whtDot} />
              </div>
              <div className="crcle">
                <img src={crclr} />
              </div>
              <div className="hedrSet">
                <NavigationMenuWeb
                  navigation={this.props.navigation}
                  id={this.props.id}
                  showHeaderOnly={true}
                  isLandingPageShowLogo={false}/>
              </div>
          
            <div className="container">
              <div className="topBacckButton"><button data-test-id='backNavMnuTw' onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow " /></button></div>
              <div className="childCompont mnuSecAllContnt">

                <div className="showOnMoblLogo">
                  <img src={katorLogo} alt="logo" />
                </div>
                <div className=" seconMnuHandel "  id="secondComponent">

                  <div className=" seconMnuHead  ">
                    <h2>Select your interests </h2>

                  </div>
                  <div className="howWouldPera seconMnuHandelPera ">
                    <p>Select all the topics that matter to you, and we'll ask relevant questions to help you choose the best candidates</p>
                  </div>
                </div>
                <div className="quizeType  buttonGroup MultyBtn" id="buttonGroupSecondCompont">
                  {this.state.selectedBtn.map((cvl:any,index:number)=>{
                    return <>
                    <Button key={index} variant="outlined" data-test-id="quizbutton" sx={{
                      fontFamily:'Inter',fontSize:'14px',fontWeight:'400',textTransform:'capitalize',padding:"6px 12px"
                    }} value={cvl.value} className= {cvl.clk?"buttonClk":'button'}
                   onClick={()=>{this.selectedBtnChange(cvl.id)}}>{cvl.value}</Button></>
                  })}
                  
                </div>

                <div className="contnuBtn "  >
                  <Button variant="contained" data-test-id='hideOnMoblsubmit' className="hideOnMobl" onClick={this.navigationNumChange} 
                  sx={{textTransform:'capitalize',fontSize:'20px',fontWeight:'700'}}>Submit <EastIcon className="lftTrght" />
                  </Button>
                  <Button variant="text" data-test-id='showOnMoblsubmit' className="showOnMobl " onClick={this.navigationNumChange} sx={{textTransform:'capitalize',
                   display:'none',
    "@media(max-width:768px)": {
      color:'#948E8D',fontSize:'16px',fontWeight:'700',margin:'70% -50% 20px 0',display:'flex',justifyContent:'center',alignItems:'center',columnGap:'40px'
    }
                      
                    }} >Submit <EastIcon />
                  </Button>

                </div>
              </div>
            </div>
            <NavigationMenuWeb      
            navigation={this.props.navigation}
            id={this.props.id}
            showFooterOnly={true}
            isLandingPageShowLogo={false}
          />
          </div>}

          {this.state.navigationNum == 2 &&
            <div className="mainFormPageQuz questionSectn QunClas" >
              <div className="container">
                <div className="topBacckButton Component"><button data-test-id='backNavMnuQ' onClick={this.navigationNumChangeDec}><ArrowBackIcon className="lftarow" /></button></div>
                <div className=" QuzAllCont">

                  <div className="showOnMoblorDektLogo">
                    <img src={katorLogo} alt="logo" />
                  </div>
                  <div className="quzeModel" id="quzeModel">
                    <div className="logoTxtQuSkp" id="Katorlogo">
                      <div className="textLogo">
                        <img src={textLogo} alt="logo" />
                      </div>
                      <span className="logoTxtQuSkpOne" id="logoTxt">{this.state.detiledQuestions[this.state.numQut]?.name}</span>
                      <span className="logoTxtQuSkpSec">Question {this.state.numQut + 1}/{this.state.detiledQuestions.length}</span>
                    </div>

                    <div className="quzeQ" id="quze">
                      <h3>{this.state.detiledQuestions[this.state.numQut]?.quest?.question}</h3>
                      <span className="votFrQt" id="dummyVot">1 (disagree) - 10 (agree)</span></div>
                    <div className="votSelecSld">
                      <Box sx={{ width: 300, color: '#DFD5C4' }}>
                        <Slider
                          aria-label="Always visible"
                          value={this.state.sliderValue}
                          onChange={this.handleSliderChange}
                          data-test-id='changeValue'
                          marks={marks}
                          min={1}
                          max={10}
                          valueLabelDisplay="on"
                          sx={{
                            "& .MuiSlider-markLabel": {
                              display: 'none'
                            },
                            "& .MuiSlider-valueLabel": {
                              backgroundColor: '#F1ECE4', color: '#524948', fontFamily: 'Inter',fontSize:'22px',fontWeight:'400',
                              " @media(max-width:768px)": {fontSize:'20px',fontWeight:'400',
                              }
                            }, "& .MuiSlider-rail": {
                              backgroundColor: '#DFD5C4', height: '17px', color: '#FFFFFF'
                            }, "& .MuiSlider-track": {
                              backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', height: '17px'
                            }, "& .MuiSlider-thumb ": {
                              backgroundColor: '#DFD5C4', boxShadow: '#DFD5C4'
                            }, "& .MuiSlider-root": {
                              backgroundColor: '#DFD5C4', borderColor: '#DFD5C4', color: '#DFD5C4', height: '17px'

                            }, "& .MuiSlider-thumb:hover": {
                              color: '#DFD5C4', boxShadow: '#DFD5C4'
                            }
                          }
                          }
                        />
                      </Box>
                    </div>
                    <div className="prvNxtBtn" id="prvNxtBtn">
                      <button type="button" data-test-id="chngePrvQut" onClick={this.chngePrvQut}>
                        <WestIcon  className="prvNxtBtnb"/>
                      </button>
                      <button type="button" id="buttonChngQutn" data-test-id="chngeQut" onClick={this.chngeQut} >
                        <EastIcon  className="prvNxtBtnb" />
                      </button>
                    </div>

                  </div>


                </div>
              </div>

            </div>
          }
             {this.state.navigationNum == 3 &&
            this.reusabelContent(marks)
          }
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start



const StyledWrapper = styled(Box)({

'& .backColor':{
  border:'1px solid #524948',margin: 'auto',    width: '95%',borderRadius:'6px',padding:'12px 19px 20px',fontFamily:'Inter',fontSize:'14px',fontWeight:'400',
  display: 'flex',rowGap:'9px',
  flexDirection: "column",color:'white',
  backgroundColor:'#524948',
  "@media(max-width:768px)": {
      margin: 'auto',    width: '85%',
    }
}, '& .manContRadoClk':{
  color:'white',display: 'flex',columnGap:'9px',
  justifyContent: 'left',
  alignItems: "center",fontFamily:'Inter',fontSize:'16px',fontWeight:'400',
},
  '& .manContRado':{
    border:'1px solid #524948',margin: 'auto',    width: '95%',borderRadius:'6px',padding:'12px 19px 20px',fontFamily:'Inter',fontSize:'14px',fontWeight:'400',
    display: 'flex',rowGap:'9px',
    flexDirection: "column",color:'#524948',
    "@media(max-width:768px)": {
      margin: 'auto',    width: '85%',
    }
  },'& .manContRdFt':{
    display: 'flex',columnGap:'9px',
    justifyContent: 'left',
    alignItems: "center",fontFamily:'Inter',fontSize:'16px',fontWeight:'400',color:'#0F172A',

  },'& .radioCustom':{
     display: 'flex',rowGap:'20px',
    justifyContent: 'left',
    alignItems: "center",flexDirection: "column",
  },
  '& .ftrSet':{
    "@media(max-width:768px)": {
    display:'none'}
  },
  '& .radZigImg':{
    position: "absolute",
    top: "5%",
    right: "0%",
    "@media(max-width:768px)": {
      position: "absolute",
      top: "680px",
      left: "-36px",
    }

  }, '& .radZigImg img':{
    height: "auto",
    maxWidth: "308.31px",
    width: "100%"   ,
    "@media(max-width:768px)": {
      height: "auto",

      maxWidth: "250.31px",
      width: "100%"   ,
      position: "absolute",
      top: "40px",
      left: "-59px",
    }
  },
  '& .crcle':{
    position: "absolute",
  left: "1px",
  zIndex: "90",
  bottom: "0%",width:"272px",
  "@media(max-width:768px)": {
      position: "absolute",
      top: "900px",
      left: "-59px",
      zIndex: "90",
    }
  },
  '& .whtdts':{
    position: "absolute",
    left: "90px",
    zIndex: "100",
    bottom: "153px",
    "@media(max-width:768px)": {
      position: "absolute",
      top: "95px",
      left: "262px",
      zIndex: "90",
    }
  },'& .whtdt':{
    "@media(max-width:768px)": {
      position: "absolute",
      top: "95px",
      left: "262px",
      zIndex: "0",
    }
  },
  "& .mainFormPage": {

    backgroundColor: '#F1ECE4',
    position:'relative',
    overflow:'hidden'

  },
  '& .topContnr':{
    position: "relative",
  zIndex: "1",
  },
  "&container": {
    maxWidth: '1440px',
    width: '100%',
    margin: '0 auto',
    padding: '0 15px',
    height: 'auto',

  },

  "& .childCompont": {
    margin: ' auto',
    width: '597px',

    "@media(max-width:768px)": {
      paddingTop: '20px',
      width: '99%',
      overflowX: 'hidden'
    }
  },
  "& .howWouldAndPera": {
    width: '597px',
    padding: '0',
    marginTop: '35px',fontFamily:'Inter',
    "@media(max-width:768px)": {
      width: '324px',
      marginTop: '0px',
      paddingLeft:'20px'
    }
  },
  "& .howWouldPera ": {
    marginTop: '0px',
    "@media(max-width:768px)": {
      width: '326px',
    }
  },
  "& .howWouldPera p": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400', lineHeight: '24px',
    marginTop: '0%',
    color: '#706968',
    "@media(max-width:768px)": {
      width: '326px',

    }
  },
  "& .howWouldHead": {
    width: '597px',
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#524948', paddingTop: '0px',
    "@media(max-width:768px)": {
      width: '326px',
      fontSize: '30px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color: '#524948', paddingTop: '0px'
    }
  },
  "& .firstQuize": {
    border: '1px solid #524948',
    borderRadius: '10px',
    display: 'flex',
    cursor: 'pointer',
    padding: '10px',
    marginBottom: '24px',
    marginRight: '21px', marginLeft: '22px',
    "@media(max-width:768px)": {
      borderRadius: '6px',
      padding: '16px 12px',
      paddingRight: '20px',
      paddingLeft: '15px',
    }

  },

  "& .firstQuizeHover": {
    backgroundColor: '#f0e68c',
  },
  "& .firstQuizeActive": {
    backgroundColor: '#524948',
  },
  "& .firstQuizeSelected": {
    backgroundColor: '#524948',

  },
  "& .radioChecked": {
    backgroundColor: '#524948',

  },
  "& .radioUnchecked": {
    backgroundColor: 'transparent'
  }
  , "& .contnuBtn": {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",

  },
 
  "& .quizeType": {
    marginTop: '20px',
    "@media(max-width:768px)": {
      marginTop: '20px',
    }


  },
  "& .hideOnMobl:hover": {
    backgroundColor: '#6699B1',   

  },
  "& .hideOnMobl": {
    display: 'flex',
    justifyContent: 'center',
    alignItem: "center",
    width: '256px',
    height: '56px',
    fontSize: '20px',
    margin: 'auto',
    marginTop: '20px',
    borderRadius: '8px',
    backgroundColor: '#6699B1',
    padding: '10px 16px',
    gap: '8px',fontWeight:'700',marginBottom:'110px',
    "@media(max-width:768px)": {
      display: 'none',
    }
  },'& .selectYTtBtn':{
marginBottom:'200px',
  }
  , "& .lftTrght": {
    width: '24px',
    height: '24px',
  },'& .radioMAnLable':{
    fontSize: "16px",   fontFamily:'Inter',fontWeight:'400'


  },
  "& .radioLabel": {
    fontSize: "14px",
    color: "#777",
    marginLeft: '-28px',
    marginTop: '5px',fontFamily:'Inter',fontWeight:'400'
  }
  , "& .topBacckButton": {
    color: 'black',
    margin: 'auto',
    width: '80%',
    paddingTop: '0px',
    "@media(max-width:768px)": {
      paddingTop: '20px',
      margin: 'auto',
      width: '100%',
    }
  },
  "& .topBacckButton button .lftarow": {
    color: 'black',
    cursor: 'pointer'
  },
  "& .topBacckButton button": {
    backgroundColor: "transparent",
    borderStyle: 'none',

  },
  "& .topBacckButton button:hover": {
    backgroundColor: "transparent",
    borderStyle: 'none'
  },
  "& .showOnMoblLogo": {
    display: 'none',
    "@media(max-width:768px)": {
      display: 'flex',
      justifyContent: 'center',
      alignItem: "start",
    }
  },
  "& .showOnMoblLogo img": {
    width: '106px',
    height: '33.3px'
  },
  "& .mainFormPageSecond": {
    backgroundColor: '#F1ECE4',position:'relative'
    
  },
  "& .mnuSecAllContnt": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
    flexDirection: "column", textAlign: 'center',
    margin: ' auto',
    width: '597px', paddingTop: '5px',
    "@media(max-width:768px)": {
      paddingTop: '20px',
      margin: ' auto',
      width: '99%',
      overflow: 'hidden', display: 'flex', justifyContent: 'center',
      alignItem: "center",
      flexDirection: "column", textAlign: 'center',
    }
  },
  "& .buttonGroup": {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center',
    alignItem: "center",
    marginTop: '20px',
    width: '597px',
    marginright: '12px',
    "@media(max-width:768px)": {
      width: '100%', display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      justifyContent: 'center',
      alignItem: "center",
    }
  },'& .contnuBtnForSecond':{
    display: 'flex',
    justifyContent: 'end',
    alignItem: "center",margin:'30px 0'
  },'& .showOnMoblSubmt':{
    display: 'none',
    "@media(max-width:768px)": {
      display: 'flex',
      textAlign: 'center',
      borderColor: 'transparent',
      color: 'black',
      fontSize: '16px',
      gap: '20px',fontWeight:'700',marginRight:'-50%',
      justifyContent: 'end',
      alignItems: "center",marginTop:'20px',narginBottom:'15px'
    }
  }, "& .buttonGroup button": {
    color: '#524948',
    borderColor: '#524948',
    fontSize: '14px', padding: '6px 12px',fontWeight:'400',
    "@media(max-width:768px)": {
      color: '#524948',
      borderColor: '#524948',
      fontSize: '14px', padding: '6px 12px',fontWeight:'400'
    }
  }, "& .buttonGroup .buttonClk": {
    color: 'white',
    backgroundColor: '#524948',
  },
  "& .seconMnuHandel": {
    textAlign: 'center',
    display: 'flex',
    gap: '0px',
    justifyContent: 'center',
    flexDirection: "column",
    alignItem: "center",
    width: '650px',
    "@media(max-width:768px)": {
      width: '100%',rowGap:'0px'
    }
  },
  "& .seconMnuHandelPera": {
    marginTop: '0px',marginBottom:'10px',
    width: '600px',
    "@media(max-width:768px)": {
      width: '100%', display: 'flex',
      justifyContent: 'center',
      alignItem: "center", 
      marginTop: '-15px',
    }
  },
  "& .seconMnuHead": {
    width: '600px',
    color: '#524948',
    paddingTop: '0',
    fontSize: '30px',
    fontWeight: '700',
    fontFamily: 'Inter',
    "@media(max-width:768px)": {
      paddingTop: '0',
      width: '100%',
      fontSize: '30px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color: '#524948',
    }
  },
  "& .mainFormPageQuz": {
    backgroundColor: '#F1ECE4',
    backgroundImage: `url(${radZigImg}),  url(${crclr})`,
    backgroundPosition: 'right 150px,  -154px -106px',
    backgroundSize: '380.31px auto,  286px 273px',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    "@media(max-width:768px)": {
      padding: '0 0px', width: '100%',
      height: '100vh',
      backgroundColor: '#F1ECE4',
      backgroundImage: `url(${radZigImg}),  url(${crclr})`,
      backgroundPosition: ' 213px 80px,  -150px -100px',
      backgroundSize: '240.5px auto,  272px 272px',
      backgroundRepeat: 'no-repeat',
    }
  },
  "& .showOnMoblorDektLogo": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
  },
  "& .showOnMoblorDektLogo img": {
    width: '233.36px', height: '73.3px',
    "@media(max-width:768px)": {
      width: '106px', height: '33.3px',
    }
  },
  "& .QuzAllCont": {
    display: 'flex', justifyContent: 'center',
    alignItem: "center",
    flexDirection: "column", textAlign: 'center',
    width: '100%', paddingTop: '0px', marginTop: '0px',
    "@media(max-width:768px)": {
      width: '99%',
      overflowX: 'hidden',
    }
  },
  "& .quzeModel": {
    backgroundColor: '#524948',
    borderTopLeftRadius: '50px', borderTopRightRadius: '50px', margin: ' 50px auto 0 auto', width: '95%', padding: '20px 5px 30px',
    "@media(max-width:768px)": {
      padding: '30px 0px 15px 0px',width: '100%',
      height:'640px',      overflowY: 'hidden',margin: ' 30px auto 0 auto'
    }
  },
  "& .logoTxtQuSkp": {
    margin: 'auto', width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', fontFamily: 'Inter', rowGap: '5px',
    "@media(max-width:768px)": {
      width: '100%',
    }
  }
  , "& .logoTxtQuSkpThrd": {
    textDecoration: 'underline', cursor: 'ponter',
    fontSize: '18px',
    fontWeight: '700', fontFamily:'Inter',
    "@media(max-width:768px)": {
      fontSize: '12px',
      fontWeight: '700', }
  },
  "& .quzeQ ": {
    margin: 'auto', width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', fontFamily: 'Inter', rowGap: "2px",
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  "& .quzeQ h3": {
    color: "white", fontSize: "32px", fontWeight: '700',
    "@media(max-width:768px)": {
      fontSize: "20px", padding: '55px 2px 0px'
    }
  },'& .votFrQt':{
    fontSize: '25px',
    fontWeight: '400', fontFamily:'Inter',
    "@media(max-width:768px)": {
      fontSize: '15px',
      fontWeight: '400', }
  }, "& .votSelecSld": {
    margin: '50px auto 10px auto', width: '50%', display: 'flex', justifyContent: 'center',
    alignItem: "center", color: '#F1ECE4', textAlign: 'center',
    "@media(max-width:768px)": {
      width: '100%',
    }
  },
  "& .prvNxtBtn": {
    margin: '20px auto', width: '85%', display: 'flex', justifyContent: 'space-between',
    alignItem: "center", color: '#F1ECE4',
    "@media(max-width:768px)": {
      width: '100%', margin: '50px auto 0px auto'
    }
  }
  , "& .prvNxtBtn button": {
    backgroundColor: 'transparent', border: 'none', color: '#F1ECE4', cursor: 'pointer',
    width: '51px',height:'51px',
    "@media(max-width:768px)": {
      width: '33.11px',height:'32.27px'    }
  }, 
  "& .prvNxtBtn button .prvNxtBtnb": {
    color: '#F1ECE4', cursor: 'pointer',
    width: '28.42px',height:'32.25px',
    "@media(max-width:768px)": {
      width: '33.11px',height:'32.27px'    }
  }

,"& .topbackmnuBtn":{
  marginTop:'70px'
}
  

})
// Customizable Area End
