import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CheckBox from "../../../components/src/CustomCheckBox";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FlatList } from "react-native-gesture-handler";
import moment from "moment";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { katorlogo, ViewVector, dotGroup, carveRound } from "./assets";
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={styles.headWraper}>
        <Box>
          <Box sx={styles.backArrowContainer}>
            <ArrowBackIcon sx={styles.backArrow} onClick={() => this.props.navigation.goBack()} data-test-id="navigatePreivous"/>
          </Box>
          <Box sx={styles.logoContainer}>
            <Box sx={styles.sublogocontainer}>
              <img style={styles.katorlogo} className="ktorlogos" src={katorlogo} alt="katorlogo" />
            </Box>
          </Box>
          <Box sx={styles.vectorContainer}>
            <Box sx={styles.subVectorContainer}>
              <img style={styles.vectorimage} src={ViewVector} alt="vector image" />
            </Box>
          </Box>
          <Box sx={styles.dotGroupContainer}>
            <img style={styles.dotGroupimage} src={dotGroup} alt="dot Group" />
          </Box>
        </Box>
        <Box sx={styles.headTearmCondition}>
          <Box sx={styles.firstheadTerm}>
            <Typography sx={styles.dateTerm} variant="body2" data-testid="currentDate">{this.state.dateFormat}</Typography>
            <Typography sx={styles.headingTerm} variant="h1">Terms and Conditions</Typography>
          </Box>
          <Box data-testid="termsCondsList">
            {this.state.termsCondsList?.map((info) => (
              <Box sx={styles.subheadTerm} key={info.id} data-testid={`term-${info.id}`}>
                <Typography sx={styles.termTitle} variant="h3" data-testid="termTitle">{info.title}</Typography>
                <Typography sx={styles.termSubtitle} variant="body2" data-testid="termSubtitle">{info.sub_title}</Typography>
                <Typography sx={styles.termPeragaph} variant="body2" data-testid="termDescription">{info.description}</Typography>
              </Box>
            ))}
          </Box>

        </Box>
        <Box sx={styles.carveRoundhead}>
          <Box sx={styles.carveRoundsubhead}>
            <img style={styles.carveRound} className="carveRound" src={carveRound} alt="carveRound image" />
          </Box>
        </Box>
        <Box sx={styles.navigationView}>
          <NavigationMenuWeb      
            navigation={this.props.navigation}
            id={this.props.id}
            showFooterOnly={true}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  navigationView: {
    display: "block",
    '@media (max-width: 660px)': {
      display: "none"
    }
  },
  carveRoundhead: {
    position: "absolute",
    bottom: "70px",
    left: "-20px",
    zIndex: "1",
    '@media (max-width: 660px)': {
      bottom: "-114px",
      left: "-30px",
      position: "fixed",
    }
  },
  carveRoundsubhead: {
    width: "362px",
    height: "252px",
    '@media (max-width: 660px)': {
      width: "210px",
      height: "160px",
    }
  },
  carveRound: {
    width: "100%",
    height: "100%",
  },
  headWraper: {
    backgroundColor: "#F1ECE4",
    position: "relative",
    width: '100%',
    // backgroundImage: `url(${carveRound})`,
    // backgroundPosition: '-60px 920px',
    // backgroundRepeat: 'no-repeat',
    "@media(max-width:660px)": {
      position: "relative",
      width: '100%',
    }
  },
  logoContainer: {
    textAlign: "center",
    marginTop: "136px",
    '@media (max-width: 660px)': {
      textAlign: "center",
      marginTop: "100px",
    },
  },
  sublogocontainer: {
    height: "73px",
    width: "233px",
    margin: "auto",
    '@media (max-width: 660px)': {
      height: "37px",
      width: "119px",
    },
  },
  katorlogo: {
    width: "100%",
    height: "100%"
  },
  backArrowContainer: {
    padding: "8px",
    position: "absolute",
    left: "128px",
    top: "127px",
    zIndex: "999",
    backgroundColor: "#F1ECE4",
    '@media (max-width: 660px)': {
      width: "100%",
      height: "48px",
      top: "0px",
      left: "0px",
      padding: "0px"
    },
  },
  backArrow: {
    height: "32px",
    width: "32px",
    '@media (max-width: 660px)': {
      left: "8px",
      padding: "10px 12px",
      gap: "8px",
    },
  },
  vectorContainer: {
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "1",
    '@media (max-width: 660px)': {
      top: "0px",
      right: "-70px",
    },
  },
  subVectorContainer: {
    width: "232px",
    height: "232px",
    '@media (max-width: 660px)': {
      width: "164px",
      height: "152px",
    },
  },
  vectorimage: {
    width: "100%",
    height: "100%"
  },
  dotGroupContainer: {
    position: "absolute",
    top: "100px",
    right: "100px",
    zIndex: "999",
    '@media (max-width: 660px)': {
      display: "none",
    },
  },
  dotGroupimage: {
    // width: "171px",
    // height: "171px",
  },
  headTearmCondition: {
    width: "46%",
    margin: "auto",
    marginTop: "60px",
    gap: "32px",
    marginBottom: "262px",
    '@media (max-width: 660px)': {
      width: "92%",
      marginLeft: "4%",
      marginTop: "50px",
      gap: "32px",
      backgroundColor: "#F1ECE4",
      position: "relative",
      zIndex: 999,
    },
  },
  firstheadTerm: {
    padding: "8px 0px",
    '@media (max-width: 660px)': {
      padding: "8px 16px",
    },
  },
  dateTerm: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "#948E8D",
  },
  headingTerm: {
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "40px",
    letterSpacing: "-0.5px",
    color: "#524948",
    marginTop: "6px"
  },
  subheadTerm: {
    gap: "10px",
    marginTop: "32px",
  },
  termTitle: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    color: "#524948",
  },
  termSubtitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "#524948",
  },
  termPeragaph: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
    color: "#706968",
  },
}
// Customizable Area End
