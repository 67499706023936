Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.httpPostMethod = "POST";

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Captcha";
exports.labelBodyText = "Captcha Body";
exports.captchaVerfy='bx_block_captcha3/captcha_verifies/verify'

exports.captchaTitle = "Test Captcha";
exports.sitekey='6Lc1G2QqAAAAAGiXBefUadWSjU1jLn9A6sqcV7YS'

// Customizable Area End
