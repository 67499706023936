import React from "react";

import {
    Box,
    Button,
    // Customizable Area Start
    styled,
Modal,
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import EastIcon from '@mui/icons-material/East';
import { radZigImg, whtDot, crclr, katorLogo, PreferdCandLogO ,prferdCnad, copy, copyLogo} from "./assets";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";

// Customizable Area End

import MultipageFormsController, {
    Props,

} from "./MultipageFormsController";
import { ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";

export default class PreferredCandidate extends MultipageFormsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
repeatedCode=()=>{
    return<>
      <div className="childCompont" >
                            <div className="preferrdCandLogoOnMob" >
                                    <img src={katorLogo} alt="logo" />
                                </div>
                                {this.state.electionDetails.map((Cvl:any)=>{
                                      return<>
                                <div className="prefferDCanDFStDv " >
                                   
                                    <div className="prefferDCanDFStDvSpan">
                                        RESULTS FOR NEXT ELECTIONS
                                    </div>

                                    <div className="prefferDCanDFStDvDate">
                                       {Cvl.date}
                                    </div>
                                </div>
                               
                                  
                                     <div className="prefferDCanDFStBTn" >
                                    <div className="logo">
                                        <img src={PreferdCandLogO} alt="logo" />
                                    </div>
                                    <div className="Text">
                                        <div className="textHead">{Cvl.elections[0].type} Election</div>
                                        <div className="textHeadSec">{Cvl.elections[0].description}</div>
                                    </div>
                                </div>
                                    </>
                                })}
                               
                                <div className="preferCandNameAndDetl" >
                                    {
                                      this.state.chatgptResponse.map((cvl:any)=>{
                                        return<>
                                        <div className="preferCandNameAndDetlCon" id="preferdCandtEght">

                                        <div className="preferCandNameAndDetlNam">{cvl.name}</div>
                                        <div className="preferCandNameAndDetlParty">{cvl.party}</div>
                                        <div className="preferCandNameAndDetlWhy">{cvl.campaignStatement}</div>
                                        </div>
                                        </>
                                        })
                                    }
                                  
                                   
                                </div>
                               
                                
                            </div>
                            <div className="SAC" style={{display:'none'}}>
                                    <p>Show All Candidates</p>
                                </div>
    </>
}
    // Customizable Area End

    render() {


        return (
            // Customizable Area Start
            <>
                <StyledWrapper>
                    <div className="mainFormPage" id="PreferedCandt">
                    <div className="topBacckButton preferCandBackMnu ">
                                <div>
                                    <button data-test-id='backNavMnu' className="backNavMnu" onClick={this.handelBackNavgtOnPr}><ArrowBackIcon className="lftarow"  />
                                    </button>
                                </div>
                                <div className="preferrdCandLogo">
                                    <img src={katorLogo} alt="logo" />
                                </div>
                                <div className="startOver"><h4 onClick={this.handleNavigateLandingPage}>START OVER</h4></div>
                            </div>
                        <div className="container" >
                        {
                    ResolveCondition(this.state.snackbarOpenRental, <Snackbar anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                     }} 
                     data-test-id='snkBAr'
                     open={this.state.snackbarOpenRental}
                      autoHideDuration={3000} 
                     onClose={this.handleSnackbarself} >
                      <Alert variant="filled" severity="success" 
                      sx={{
                        fontSize:'17px',fontWeight:'700'
                      }}>
                      Loaded Successfully</Alert>
                  </Snackbar>,'')
                  }
                        {this.repeatedCode()}
                                
                            <div className="contnuBtnPreferd " id="preferdCandtElvn" >
                                <Button variant="contained" className="hideOnMobl preferdcadSharBtn"
                                    onClick={this.modalOpen} sx={{
                                        textTransform: 'capitalize', fontSize: '16px', fontWeight: '700', color: 'white', cursor: 'pointer'
                                    }} data-test-id='ShareResult'
                                >Share Results <EastIcon className="lftTrght" />
                                </Button>
                                <Button variant="text" className="showOnMobl preferdcadSharBtnOnMobl" onClick={this.modalOpen} sx={{
                                    textTransform: 'capitalize', fontSize: '16px', fontWeight: '700', color: '#524948', cursor: 'pointer'
                                }} data-test-id='ShareResult'
                                >Share Results <EastIcon className="prferCandEstLogoMobl shareBtnFMoble shareRresult" />
                                </Button>

                            </div>
                            <Modal
                                open={this.state.modalOPen}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                
                            >
                                <Box sx={{
                                    display:'grid',placeItems:'center',
                                   
                                     p: 4,borderRadius:'40px',borderStyle:'none',fontFamily:'Inter',height:'100vh',
                                     "&:focus-visible": {
                                        outline: 'none', 
                                      },
                                     
                                }}>
                                     <StyledWrapper>
                                    <div className="ManContModal">
                                        <div className="ContnrModal sendLinkModal">
                                            <div className="FtHadPera">
                                                <h3>Which candidate represents you the most? Answer the quiz </h3>
                                                <div className="copyLink">
                                                    <p id="Link">{this.state.pathUrl}</p>
                                                    <Tooltip  title={
                                                    
                                                        <Box 
                                                        sx={{ 
                                                        display: 'flex', 
                                                        alignItems: 'center', 
                                                        gap: 1,
                                                        color:'text'
                                                        }}
                                                    >
                                                        <img src={copyLogo} alt='logo' className="tollLogo"/>
                                                        <Box >URL copied</Box>
                                                    </Box>
                                                    }
                                                    >
                                                    <img src={copy} alt="logo" onClick={this.copyPath}/>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="EmailField">
                                                <label className="lable">Email </label>
                                                <input type="email" className="emailInput"  data-test-id='emailInput' placeholder="name@email.com" onChange={(e:any)=>this.validateEmail(e)}/>

                                                {ResolveCondition(this.state.emailValidt,'',<div className={
                                                   "valdErr"
                                                }>Please enter a valid email</div>)}
                                            </div>

                                            <div className="sendBtnDv" id="sendBtnDv">
                                                <Button variant="contained" 
                                                disabled={this.state.shareResutEmail != "" ? false : true}
                                                data-test-id='sendResult'
                                                    sx={{
                                                        textTransform: 'capitalize', fontSize: '20px', fontWeight: '700',
                                                        boxShadow: '0px', borderRadius: '8px', padding: '10px 16px',
                                                        width: '100%', backgroundColor: '#6699B1', cursor: 'pointer',color:'white',
                                                        '&:hover': { backgroundColor: '#6699B1', boxShadow: 'none' },
                                                        display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: '8px', '&:active': { 
                                                            boxShadow: 'none', 
                                                            transform: 'none',
                                                          },
                                                        "@media(max-width:768px)": {
                                                            textTransform:'capitalize',fontSize:'16px',fontWeight:'700',color: '#948E8D',cursor:'pointer',backgroundColor:'transparent',boxShadow:'0',
                                                            '&:hover': {
                                                                backgroundColor: 'transparent', 
                                                                boxShadow: 'none', 
                                                                transition: 'none',
                                                              },marginTop:'0px'                                                        }
                                                    }}  onClick={()=>{
                                                        this.getCategoryApi()
                                                    }}>
                                                    Send <EastIcon className="prferCandEstLogoMoblPop" 
                                                    />
                                                </Button>
                                            </div>

                                            <div className="hrBordr"></div>

                                            <div className="closeBtnClk">
                                                <Button variant="contained" data-test-id='ShareResultClose' onClick={this.modalClose}
                                                    sx={{
                                                        textTransform: 'capitalize', fontSize: '16px', fontWeight: '700',
                                                        width: '100%', backgroundColor: '#524948', padding: '10px 16px',
                                                        borderRadius: '8px', cursor: 'pointer',
                                                        '&:hover': { backgroundColor: '#524948', boxShadow: 'none', },
                                                    }}
                                                    >
                                                    Close
                                                </Button>
                                            </div>
                                        </div>


                                    </div> </StyledWrapper>
                                </Box>
                            </Modal>
                      
                        
           </div>
           <div className="ftrSet">
              <NavigationMenuWeb      
            navigation={this.props.navigation}
            id={this.props.id}
            showFooterOnly={true}
            isLandingPageShowLogo={false}
          /></div>
              </div>
                      


                </StyledWrapper>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start



const StyledWrapper = styled(Box)({
   
    "& .mainFormPage": {
        backgroundColor: '#F1ECE4',
        backgroundImage: `url(${radZigImg}), url(${whtDot}), url(${crclr})`,
        backgroundPosition: 'right 120px, 83px 581px, -4px 631px',
        backgroundSize: '423.31px auto,  171px 171px, 248px ',
        backgroundRepeat: 'no-repeat',
        width: '100%',


        "@media(max-width:768px)": {
            padding: '0 0px', width: '100%',
            backgroundImage: `url(${radZigImg}), url(${prferdCnad})`,
            backgroundPosition: '-63px 1210px,  305px 55px',
            backgroundSize: '209px 150px, 100px 84px',
            backgroundRepeat: 'no-repeat',

        }
    },
    "&container": {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        padding: '0 15px',

    },

    "& .childCompont": {
        margin: ' auto',
        width: '50%',
        paddingTop: '50px',
        fontFamily: 'Inter',
        "@media(max-width:768px)": {
            paddingTop: '30px',
            width: '99%',
            overflowX: 'hidden',
        }
    }
    , 
    "& .hideOnMobl:hover": {
        backgroundColor: '#6699B1',

    },
    "& .hideOnMobl": {
        display: 'flex',
        justifyContent: 'center',
        alignItem: "center",
        width: '256px',
        height: '56px',
        fontSize: '20px',
        margin: 'auto',
        marginTop: '20px',
        borderRadius: '8px',
        backgroundColor: '#6699B1',
        padding: '10px 16px',
        gap: '8px',
        "@media(max-width:768px)": {
            display: 'none',
        }
    }
    , "& .lftTrght": {
        width: '24px',
        height: '24px',
    },
     "& .topBacckButton": {
        color: 'black',
        margin: 'auto',
        width: '80%',
        paddingTop: '120px',
        "@media(max-width:768px)": {
            paddingTop: '20px',
            margin: 'auto',
            width: '100%',
        }
    },
    "& .topBacckButton button .lftarow": {
        color: 'black',
        cursor: 'pointer',paddingTop:'25px',paddingLeft:'5px',
        "@media(max-width:768px)": {
            paddingTop:'0px',
        }
    },
    "& .topBacckButton button": {
        backgroundColor: "transparent",
        borderStyle: 'none',

    },
    "& .topBacckButton button:hover": {
        backgroundColor: "transparent",
        borderStyle: 'none'
    },
    "& .showOnMoblLogo": {
        display: 'none',
        "@media(max-width:768px)": {
            display: 'flex',
            justifyContent: 'center',
            alignItem: "center",
        }
    },
    "& .showOnMoblLogo img": {
        width: '106px',
        height: '33.3px'
    },
    '& .preferCandBackMnu': {
        
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: "center", paddingTop: '0px', textAlign: 'center',
        fontFamily:'Inter',fontSize:'24px',fontWeight:'700',textDecoration:'underline',color:'#524948',cursor:'pointer',width:'100%',
        "@media(max-width:768px)": {
            fontSize:'14px',fontWeight:'700',padding:'0px',borderBottom: '2px solid #524948',display: 'flex',
            justifyContent: 'space-between',
            alignItem: "center",
        }
    },
    '& .preferrdCandLogo': {
        paddingTop: '30px',marginLeft:'10px',
        "@media(max-width:768px)": {
            display: 'none',
           
        }

    },'& .preferrdCandLogoOnMob':{
        display: 'none',

        "@media(max-width:768px)": {
            display: 'block', 
 padding:'0 0 5% 31%',
        }
    },'& .preferrdCandLogoOnMob img':{
        display: 'none',

        "@media(max-width:768px)": {
            display: 'block', width: '106px',
            height: '33.3px'
        }
    }, '& .backNavMnu ': {
        paddingTop: '15px',marginLeft:'137px',
        "@media(max-width:768px)": {
            marginLeft:'13px',
        }
    },
    '& .startOver ': {
        paddingTop: '5px',marginRight:'128px',
        "@media(max-width:768px)": {
            paddingTop: '0px',paddingRight:'10px',marginRight:'12px',

        }
    },'& .startOver h4': {
        fontSize:'24px',fontWeight:'700',textDecoration:'underline',
        "@media(max-width:768px)": {
            paddingTop: '0px',fontSize:'14px',fontWeight:'700',textDecoration:'underline',

        }
    }, "& .prefferDCanDFStDv": {
        "@media(max-width:768px)": {
            padding:'0 10px'

        }
    }, "& .prefferDCanDFStDvDate": {
        fontSize: '30px', padding: '0px', fontWeight: '700'
    }, "& .prefferDCanDFStDvSpan": {
        fontSize: '14px', padding: '0px', fontWeight: '400', color: '#948E8D'

    },"& .prefferDCanDFStBTn":{
        display:'flex',columnGap:'5px',padding:'16px 12px 16px 20px',borderRadius:'6px',border:' 1px solid #524948',marginTop:'20px',backgroundColor:'#F1ECE4',
         "@media(max-width:768px)": {
            margin:'5px 10px'

        }
    },"& .textHead":{
        fontSize:'16px',color: '#524948', fontWeight: '700'
    },"& .textHeadSec":{
        fontSize:'14px',color: '#706968', fontWeight: '400',
    },"& .logo":{
        height:'30px',width:'30px',
        "@media(max-width:768px)": {
         width: '27.6px',
            height: '26.4px'
        }
    },'& .preferCandNameAndDetl':{
        display: 'flex',
        justifyContent: 'center',
        alignItem: "center",flexDirection:'column',
        marginTop:'20px',rowGap:'20px',
    },'&  .preferCandNameAndDetlCon':{
        display: 'flex',
        justifyContent: 'center',
        alignItem: "center",flexDirection:'column',
        marginTop:'4px',rowGap:'4px',
        "@media(max-width:768px)": {
            margin:'5px 10px'

        }    },'& .preferCandNameAndDetlNam':{
        fontSize:'24px', fontWeight: '700',
    },'& .preferCandNameAndDetlParty':{
        fontSize:'16px',color: '#524948', fontWeight: '400'
    },'& .preferCandNameAndDetlWhy':{
        fontSize:'14px',color: '#706968', fontWeight: '400'
    },'& .contnuBtnPreferd':{
        paddingBottom:'20px',
        "@media(max-width:768px)": {
            display: 'flex',
            justifyContent: 'end',
            alignItem: "center", color:'black',
        }
    },'& .showOnMobl ':{
        display:'none',
        "@media(max-width:768px)": {
            display: 'flex',
            justifyContent: 'end',
            alignItem: "center", color:'black',gap:'30px',cursor:'pointer'
        }
    },'& .showOnMobl .preferdcadSharBtn':{
      border:'1px solid #F1ECE4'
    },"& .prferCandEstLogoMobl":{
        height:'40px',width:'51px',color: '#524948'

    },'& .preferdcadSharBtnOnMobl':{
        color: '#524948',fontSize:'16px',fontWeight:'700'
    },'& .sendBtnDv':{
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",paddingLeft:'55%'

    },
    '& .sendLinkModal':{borderRadius:'40px',
margin:'auto',padding:'80px', display: 'flex',
flexDirection:'column',rowGap:'32px',backgroundColor:'#F1ECE4',width:'683px',
"@media(max-width:768px)": {
    position: 'fixed',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)', 
    width: '100%',
    height: '440px',
    padding: '30px 16px 0px',
    top: 'unset',
    backgroundColor: '#F1ECE4',
    opacity: '1',
    borderRadius:'30px',
    borderBottomLeftRadius: '0px', 
    borderBottomRightRadius: '0px',
    transition: 'all 0.3s ease-in-out',
    
}
    },
    '& .ContnrModal':{
        Fontfamily:'Inter',
        
      },

"& .copyLink": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center", fontFamily:'Inter', fontSize:'14px', fontWeight: '400',marginTop:'-3%',
    borderBottom:'1px solid #CFCDCC',
   
},
'& .FtHadPera':{
     display: 'flex',
    justifyContent: 'center',
    alignItem: "center",flexDirection:'column',rowGap:'0px',padding:'0px',
    "@media(max-width:768px)": {
        padding:'0 15px',
fontFamily: "Inter",
fontSize: "100px",
fontWeight: "400",
textAlign: "left",
}
},
'& .FtHadPera h3':{
    fontSize:'16px', fontWeight: '400', fontFamily:'Inter',color:'#524948'
},
'& .EmailField':{
    fontFamily:'Inter',
    display: 'flex',
    justifyContent: 'center',
    alignItem: "center",flexDirection:'column',rowGap:'10px',
    "@media(max-width:768px)": {
        padding:'0 16px',
    }
},"& .copyLink p": {
     fontFamily:'Inter',
      fontSize:'14px', 
      fontWeight: '400',color:'#706968',
    "@media(max-width:768px)": {
        padding: '0 0px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        textAlign: "left",
}
},"& .copyLink img": {width:'24px',height:'24px',
   },"& .emailInput":{
    borderRadius:'8px',padding:'10px 8px',fontSize:'16px',fontWeight:'400',fontFamily:'Inter',border:'1px solid #948E8D',backgroundColor:'#F1ECE4', 
    outline: 'none'
   },
   "& .hrBordr":{    borderBottom:'1px solid #CFCDCC',
   "@media(max-width:768px)": {
   marginTop:'-5%'
}

   },"& .lable":{
    fontSize:'14px',fontWeight:'700',color:'#524948'
   },"& .valdErr":{    fontSize:'12px',fontWeight:'400',color:'red'


   },'& .valErrHd':{
    display:'none'
   },
   "& .ManContModal":{
width:'100%',display:'grid',placeItems:'center',zIndex:'1000',

   },
   
  "& .SAC":{
    fontFamily:'Inter',
    display: 'flex',
    justifyContent: 'center',
    alignItem: "center",margin:'1% 0 1%'
  },
  "& .SAC p":{
    fontFamily:'Inter',fontSize:'16px',fontWeight:'700',color:'#524948',textDecoration:'underline',cursor:'pointer'
  },
  '& .prferCandEstLogoMoblPop':{
    color:'white',
    "@media(max-width:768px)": {
    
        height:'51px',width:'51px',color: '#948E8D'
    }

  },
  '& .prferCandEstLogoMoblPopDOne':{
    color:'white',
    "@media(max-width:768px)": {
    
        height:'51px',width:'51px',color: '#948E8D'
    }

  },'& .tooltip':{
    display: 'flex',
    alignItem: "center", gap: '4px' ,
  },'& .tollLogo':{
width:'20px',color:'white'
  },
  '& .ftrSet':{
    "@media(max-width:768px)": {
    display:'none'}
  }
})
// Customizable Area End
