Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.landingPageTitle = "Local Elections Matter The Most";
exports.landingPageSubtitle = "Kator is committed to using machine learning to improve the process. Try our brief candidate preference survey.";
exports.landingPageSubtitle2 = "Optimized for mobile browsers.";
exports.Begin = "Begin";
exports.aboutus = "About us";
exports.aboutusDescription = "We are an experimental product designed to improve access to information for local elections. While we do our best to eliminate errors, due to the use of generative and experimental AI, mistakes can occur. You should not rely solely on this product for information on local elections and should always conduct your own independent research. Consider this product a proof of concept or a starting point.";
exports.Start = "Start";
exports.contactus = "Contact Us";
exports.contactusDescription = "Fill out this form if you wish to contact us";
exports.fullName = "Full Name";
exports.Email = "Email";
exports.contactusTextareaLabel = "What would you like to ask about?";
exports.Send = "Send";
exports.privacypolicytext = "Privacy Policy";
exports.katortext = "Kator 2024";
exports.termsconditionstext = "Terms and Conditions";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End